import {
  Box,
  Card,
  CardActions,
  CardContent,
  Icon,
  SwipeableDrawer,
  TextField,
} from '@material-ui/core';
import React, { Dispatch, useState, useContext } from 'react';
import { Container, Draggable, DropResult } from 'react-smooth-dnd';
import { MESSAGES } from '../../constants/messages';
import { isEmpty, isMobile } from '../../helpers/helpers';
import ReportGroup from '../../models/reportGroup';
import { Action } from '../../reducers/formsReducer';
import { AddButton } from './AddButton';
import { DeleteButton } from './DeleteButton';
import { EditButton } from './EditButton';
import FormulaBlock from './FormulaBlock';
import { useCardClasses } from './Styled';
import { Title } from './Title';
import { IsReadOnlyContext } from '../../pages/Admin';

type Props = {
  formId: string;
  group: ReportGroup;
  dispatch: Dispatch<Action>;
};

const ReportGroupBlock: React.FC<Props> = ({ formId, group, dispatch }) => {
  const classes = useCardClasses();
  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const isReadOnly = useContext(IsReadOnlyContext);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    updateGroup(formId, group.id, event.target.value);

  const updateGroup = (formId: string, groupId: string, name: string) =>
    dispatch({ type: 'UPDATE_GROUP', payload: { formId, groupId, name } });

  const openGroup = () => setIsGroupOpen(true);
  const closeGroup = () => setIsGroupOpen(false);

  const removeGroup = (id: string) =>
    dispatch({
      type: 'REMOVE_GROUP',
      payload: { formId, groupId: id },
    });

  const addFormula = () =>
    dispatch({
      type: 'ADD_FORMULA',
      payload: { formId, groupId: group.id },
    });

  const onDrop = (
    { removedIndex, addedIndex }: DropResult,
    type: 'REORDER_FORMULA'
  ) =>
    dispatch({
      type,
      payload: {
        formId,
        groupId: group.id,
        removedIndex: removedIndex as number,
        addedIndex: addedIndex as number,
      },
    });

  const onFormulaDrop = (result: DropResult) =>
    onDrop(result, 'REORDER_FORMULA');

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <TextField
            fullWidth
            variant="outlined"
            label={MESSAGES.name}
            value={group.name}
            onChange={onNameChange}
            disabled={isReadOnly}
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <EditButton label={MESSAGES.formulas} onClick={openGroup} />
          {!isReadOnly && (
            <>
              <DeleteButton onClick={() => removeGroup(group.id)} />
              <Box style={{ marginLeft: 'auto' }}>
                <Icon className="drag-handle">drag_handle</Icon>
              </Box>
            </>
          )}
        </CardActions>
      </Card>

      <SwipeableDrawer
        anchor="right"
        open={isGroupOpen}
        onClose={closeGroup}
        onOpen={openGroup}
      >
        <Box p={2} style={{ width: !isMobile() ? '70vw' : 'auto' }}>
          <Title
            onClick={closeGroup}
            title={`${group.name} - ${MESSAGES.formulas}`}
          />
          {!isEmpty(group.formulas) ? (
            <Container
              dragHandleSelector=".drag-handle"
              lockAxis="y"
              onDrop={onFormulaDrop}
            >
              {group.formulas.map((formula) => (
                <Draggable key={formula.id}>
                  <FormulaBlock
                    formId={formId}
                    groupId={group.id}
                    formula={formula}
                    dispatch={dispatch}
                  />
                </Draggable>
              ))}
            </Container>
          ) : (
            <Box pb={5}>{MESSAGES.noFormulas}</Box>
          )}
          {!isReadOnly && <AddButton onClick={addFormula} />}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default ReportGroupBlock;
