import axios from 'axios';
import { ENDPOINTS } from '../constants/endpoints';
import { Table } from '../models/table';
import { logError } from '../helpers/helpers';
import { data as testData } from '../constants/data';

interface TablesData {
  records: {
    id: string;
    name: string;
    label: string;
    data: string;
    header: string;
  }[];
}

export const getAllTables = async () => {
  try {
    const { data } = await axios.get<TablesData>(ENDPOINTS.tablesGetAll);
    const tables = data.records.map((item) => JSON.parse(item.data)) as Table[];
    return tables;
  } catch (error) {
    if (process.env.NODE_ENV === 'production') {
      throw logError(error);
    } else {
      return testData.tables;
    }
  }
};

export const getTableById = async (id: string) => {
  try {
    const response = await axios.get<Table>(ENDPOINTS.tablesGet, {
      params: { id },
    });

    return response.data;
  } catch (error) {
    throw logError(error);
  }
};

export const deleteTable = async (id: string, token: string) => {
  try {
    await axios.delete(ENDPOINTS.tablesDelete, {
      data: { id },
      params: { token },
    });
  } catch (error) {
    throw logError(error);
  }
};

export const createTable = async (table: Table, token: string) => {
  const data = {
    id: table.id,
    name: table.name,
    label: table.label,
    data: JSON.stringify(table),
    header: '{}',
  };

  try {
    await axios.post(ENDPOINTS.tablesCreate, data, { params: { token } });
  } catch (error) {
    throw logError(error);
  }
};

export const updateTable = async (table: Table, token: string) => {
  const data = {
    id: table.id,
    name: table.name,
    label: table.label,
    data: JSON.stringify(table),
    header: '{}',
  };

  try {
    await axios.post(ENDPOINTS.tablesUpdate, data, { params: { token } });
  } catch (error) {
    throw logError(error);
  }
};
