import { FormControl, InputLabelProps, TextField } from '@material-ui/core';
import React from 'react';
import { validateNumber } from '../helpers/validation';
import { NumberFormatInput } from './common/NumberFormatInput';
import {
  DEFAULT_DECIMAL_SEPARATOR,
  DEFAULT_THOUSAND_SEPARATOR,
} from '../constants/config';

type Props = {
  name: string;
  label: string;
  minValue?: number;
  maxValue?: number;
  value: string | number | undefined;
  placeholder?: string;
  isValid: boolean;
  validationMessage: string | null;
  onChange: (name: string, value: string | number | undefined) => void;
};

const NumberInput: React.FC<Props> = (props) => {
  const {
    name,
    label,
    value,
    minValue,
    maxValue,
    placeholder,
    isValid,
    validationMessage,
    onChange,
  } = props;

  const handleValueChange = (value?: number) => onChange(name, value);

  // custom number validations do not disable steps for now
  const validation = validateNumber(value, minValue, maxValue);
  const error = !validation.isValid || !isValid;
  const message = validation.validationMessage || validationMessage;

  const inputLabelProps = {} as Partial<InputLabelProps>;

  if (placeholder) {
    inputLabelProps.shrink = true;
  }

  return (
    <FormControl fullWidth>
      <NumberFormatInput
        customInput={TextField}
        thousandSeparator={DEFAULT_THOUSAND_SEPARATOR}
        decimalSeparator={DEFAULT_DECIMAL_SEPARATOR}
        label={label}
        value={value}
        InputProps={{ inputProps: { min: minValue, max: maxValue } }}
        onValueChange={(value) => handleValueChange(value.floatValue)}
        error={error}
        helperText={message}
        placeholder={placeholder}
        InputLabelProps={inputLabelProps}
      />
    </FormControl>
  );
};

export default NumberInput;
