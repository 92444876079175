import { Button, Fab, Icon, makeStyles, Zoom } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useContext } from 'react';
import { v4 } from 'uuid';
import { MESSAGES } from '../../../constants/messages';
import { isEmpty } from '../../../helpers/helpers';
import { useTables } from '../../../hooks/useTables';
import TableBlock from '../TableBlock';
import { TokenContext } from '../../../pages/Admin';
import { IsReadOnlyContext } from '../../../pages/Admin';
import { IS_DEBUG } from '../../../constants/config';

const useStyles = makeStyles({
  fab: {
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
    zIndex: 10000,
  },
});

const AdminTables = () => {
  const [{ tables, isLoading }, dispatch] = useTables();
  const classes = useStyles();
  const token = useContext(TokenContext);
  const isReadOnly = useContext(IsReadOnlyContext);

  const importTable = async () => {
    try {
      const text = await navigator.clipboard.readText();

      const parsed = text
        .trim()
        .split('\n')
        .filter((row) => row)
        .map((row) =>
          row
            .trim()
            .split('\t')
            .map((cell) => cell.trim())
        );

      const types = parsed[0].map((cell) =>
        isNaN(Number(cell.replace(',', '.'))) ? 'text' : 'number'
      );

      const data = parsed.map((row) =>
        row.map((cell, index) => {
          const isNumber = types[index] === 'number';

          if (!isNumber) {
            return cell;
          }

          const parsedCell = parseFloat(cell.replace(',', '.'));

          if (!isNaN(parsedCell)) {
            return parsedCell;
          }

          return null;
        })
      );

      const table = {
        id: v4(),
        data,
        header: types,
        types,
        name: MESSAGES.table,
        label: MESSAGES.newTable,
        isDirty: false,
      };

      dispatch({ type: 'ADD_TABLE', payload: { table, token } });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box p={2}>
        <h2>{MESSAGES.tables}</h2>
      </Box>

      <Box p={2}>
        {isLoading ? (
          <>
            <Skeleton height={140} />
            <Skeleton height={140} />
          </>
        ) : !isEmpty(tables) ? (
          tables.map((table) => (
            <TableBlock
              types={table.types}
              key={table.id}
              tableId={table.id}
              header={table.header}
              data={table.data}
              name={table.name}
              label={table.label}
              dispatch={dispatch}
            />
          ))
        ) : (
          <Box pb={5}>{MESSAGES.noTables}</Box>
        )}

        {!isReadOnly && (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            startIcon={<Icon>publish</Icon>}
            onClick={importTable}
          >
            {MESSAGES.import}
          </Button>
        )}
      </Box>

      <Zoom in={!!tables.find((table) => table.isDirty)}>
        <Fab
          variant="extended"
          color="primary"
          aria-label={MESSAGES.save}
          className={classes.fab}
          onClick={() => {
            dispatch({ type: 'SAVE_TABLES', payload: { tables, token } });

            if (IS_DEBUG) {
              navigator.clipboard.writeText(JSON.stringify(tables));
            }
          }}
        >
          <Icon>save</Icon>
          <Box ml={1}>{MESSAGES.save}</Box>
        </Fab>
      </Zoom>
    </>
  );
};

export default AdminTables;
