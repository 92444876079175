import { Parser } from 'hot-formula-parser';
import { findNearestValue, changeDateAndNumberFormat } from './helpers';

type Table = (string | number)[][];

type LookupParams = [(string | number)?, Table?, number?, boolean?];

export const error = {
  value: new Error('#VALUE!'),
  ref: new Error('#REF!'),
  na: new Error('#N/A'),
};

export function VLOOKUP([needle, table, index, rangeLookup]: LookupParams) {
  if (!needle || !table || !index) {
    return error.na;
  }

  rangeLookup = rangeLookup || false;
  for (var i = 0; i < table.length; i++) {
    var row = table[i];
    if (
      (!rangeLookup && row[0] === needle) ||
      row[0] === needle ||
      (rangeLookup &&
        typeof row[0] === 'string' &&
        row[0].toLowerCase().indexOf(needle.toString().toLowerCase()) !== -1) ||
      (rangeLookup &&
        typeof row[0] === 'number' &&
        row[0] <= parseFloat(needle.toString()) &&
        (!table[i + 1] || table[i + 1][0] > parseFloat(needle.toString())))
    ) {
      return index < row.length + 1 ? row[index - 1] : error.ref;
    }
  }

  const [firstRow] = table;
  const isValueInRow = index < firstRow.length + 1;
  const value = isValueInRow ? firstRow[index - 1] : error.ref;

  return value;
}

const transpose = function (matrix: Table) {
  if (!matrix) {
    return error.value;
  }

  return matrix[0].map(function (col, i) {
    return matrix.map(function (row) {
      return row[i];
    });
  });
};

const utils = { transpose };

export function HLOOKUP([needle, table, index, rangeLookup]: LookupParams) {
  if (!needle || !table || !index) {
    return error.na;
  }

  rangeLookup = rangeLookup || false;

  var transposedTable = utils.transpose(table);

  if (transposedTable instanceof Error) {
    return error.value;
  }

  for (var i = 0; i < transposedTable.length; i++) {
    var row = transposedTable[i];
    if (
      (!rangeLookup && row[0] === needle) ||
      row[0] === needle ||
      (rangeLookup &&
        typeof row[0] === 'string' &&
        row[0].toLowerCase().indexOf(needle.toString().toLowerCase()) !== -1) ||
      (rangeLookup &&
        typeof row[0] === 'number' &&
        row[0] <= parseFloat(needle.toString()) &&
        (!transposedTable[i + 1] ||
          transposedTable[i + 1][0] > parseFloat(needle.toString())))
    ) {
      return index < row.length + 1 ? row[index - 1] : error.ref;
    }
  }

  return error.na;
}

type IndexParams = [Table, number, number];

export const INDEX = ([cellRange, rowNumber, columnNumber]: IndexParams) => {
  columnNumber = columnNumber ? columnNumber : 1;
  rowNumber = rowNumber ? rowNumber : 1;

  if (rowNumber <= cellRange.length) {
    if (columnNumber <= cellRange[rowNumber - 1].length) {
      const value = cellRange[rowNumber - 1][columnNumber - 1];

      if (!value) {
        return findNearestValue(cellRange, rowNumber - 1, columnNumber - 1);
      }

      return value;
    }
  }

  return error.ref;
};

export const FORMAT = ([value]: [string | number]) => {
  if (!value) {
    return error.na;
  }

  const formatted = changeDateAndNumberFormat(value);

  if (!formatted) {
    return error.value;
  }

  return formatted;
};

const parser = new Parser();

parser.setFunction('VLOOKUP', VLOOKUP);
parser.setFunction('HLOOKUP', HLOOKUP);
parser.setFunction('INDEX', INDEX);
parser.setFunction('FORMAT', FORMAT);

export default parser;
