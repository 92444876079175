import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import React, { useContext } from 'react';
import { IsReadOnlyContext } from '../../pages/Admin';

type Value = {
  label: string;
  value: string;
};

type Props = {
  label: string;
  values: Value[];
  value: string;
  name: string;
  onChange: SelectInputProps['onChange'];
};

const SelectInput: React.FC<Props> = (props) => {
  const { label, values, value, name, onChange } = props;
  const isReadOnly = useContext(IsReadOnlyContext);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        name={name}
        onChange={onChange}
        disabled={isReadOnly}
      >
        {values.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
