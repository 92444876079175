import { Dispatch, useEffect, useReducer, useCallback } from 'react';
import { formsDispatchMiddleware } from '../middleware/formsMiddleware';
import { Action, formsReducer, State } from '../reducers/formsReducer';

export const useForms = (): [State, Dispatch<Action>] => {
  const [state, dispatch] = useReducer(formsReducer, []);

  const dispatchWithMiddleware = useCallback(
    formsDispatchMiddleware(dispatch),
    [dispatch]
  );

  useEffect(() => {
    dispatchWithMiddleware({ type: 'REQUEST_FORMS' });
  }, [dispatchWithMiddleware]);

  return [state, dispatchWithMiddleware];
};
