import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  Icon,
  TextField,
} from '@material-ui/core';
import React, { Dispatch, useContext, useState } from 'react';
import {
  DEFAULT_DECIMAL_SEPARATOR,
  DEFAULT_THOUSAND_SEPARATOR,
} from '../../constants/config';
import { MESSAGES, TYPE_MESSAGES } from '../../constants/messages';
import { CustomField } from '../../models/field';
import { IsReadOnlyContext } from '../../pages/Admin';
import { Action } from '../../reducers/formsReducer';
import { NumberFormatInput } from '../common/NumberFormatInput';
import DatePickerInput from '../DatePickerInput';
import { DeleteButton } from './DeleteButton';
import { OptionsContext } from './FormBlock';
import FormulaAutocomplete from './FormulaAutocomplete';
import GroupedSelectField from './GroupedSelectField';
import MultiSelectField from './MultiSelectField';
import { SelectField } from './SelectField';
import SelectInput from './SelectInput';
import { useCardClasses } from './Styled';

type Props = {
  field: CustomField;
  formId: string;
  stepId: string;
  dispatch: Dispatch<Action>;
};

const FieldBlock: React.FC<Props> = ({ field, formId, stepId, dispatch }) => {
  const [isOpened, setIsOpened] = useState(false);
  const classes = useCardClasses();
  const options = useContext(OptionsContext);
  const isReadOnly = useContext(IsReadOnlyContext);

  const typeValues = Object.entries(TYPE_MESSAGES).map(([value, label]) => ({
    value,
    label,
  }));

  const removeField = (id: string) =>
    dispatch({
      type: 'REMOVE_FIELD',
      payload: { formId, stepId, fieldId: id },
    });

  const handleDefaultValueInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(target.name, target.value);

  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(target.name, target.value);

  const handleSelectChange = ({
    target,
  }: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => handleChange(target.name as string, target.value as string);

  const handleNumberValueInputChange = (name: string, value?: number) =>
    handleChange(name, value);

  const handleChange = (name: string, value?: string | number) => {
    const newField = { ...field, [name]: value } as CustomField;

    dispatch({
      type: 'UPDATE_FIELD',
      payload: { formId, stepId, field: newField },
    });
  };

  return (
    <>
      <Card className={classes.card}>
        <Box mr={2} display="flex" justifyContent="space-between">
          <Button
            onClick={() => setIsOpened((prevIsOpened) => !prevIsOpened)}
            endIcon={
              <Icon>{isOpened ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
            }
          >
            {field.label}
          </Button>
          {!isReadOnly && <Icon className="drag-handle">drag_handle</Icon>}
        </Box>
        <Collapse in={isOpened}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label={MESSAGES.label}
                  fullWidth
                  variant="outlined"
                  name="label"
                  value={field.label}
                  onChange={handleInputChange}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={MESSAGES.name}
                  fullWidth
                  variant="outlined"
                  name="name"
                  value={field.name}
                  onChange={handleInputChange}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label={MESSAGES.type}
                  value={field.type}
                  onChange={handleSelectChange}
                  values={typeValues}
                  name="type"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={MESSAGES.placeholder}
                  fullWidth
                  variant="outlined"
                  name="placeholder"
                  value={field.placeholder ?? ''}
                  onChange={handleInputChange}
                  disabled={isReadOnly}
                />
              </Grid>
              {field.type === 'date' && (
                <Grid item xs={12}>
                  <DatePickerInput
                    name="defaultValue"
                    label={MESSAGES.defaultValue}
                    value={field.defaultValue}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
              )}
              {field.type === 'number' && (
                <Grid item xs={12}>
                  <NumberFormatInput
                    customInput={TextField}
                    thousandSeparator={DEFAULT_THOUSAND_SEPARATOR}
                    decimalSeparator={DEFAULT_DECIMAL_SEPARATOR}
                    label={MESSAGES.defaultValue}
                    variant="outlined"
                    fullWidth
                    name="defaultValue"
                    value={field.defaultValue ?? ''}
                    onChange={handleDefaultValueInputChange}
                    disabled={isReadOnly}
                    onValueChange={(value) =>
                      handleNumberValueInputChange(
                        'defaultValue',
                        value.floatValue
                      )
                    }
                  />
                </Grid>
              )}
              {field.type !== 'date' && field.type !== 'number' && (
                <Grid item xs={12}>
                  <TextField
                    label={MESSAGES.defaultValue}
                    type="text"
                    variant="outlined"
                    fullWidth
                    name="defaultValue"
                    value={field.defaultValue ?? ''}
                    onChange={handleDefaultValueInputChange}
                    disabled={isReadOnly}
                  />
                </Grid>
              )}
              {field.type === 'number' && (
                <>
                  <Grid item xs={6}>
                    <NumberFormatInput
                      customInput={TextField}
                      thousandSeparator={DEFAULT_THOUSAND_SEPARATOR}
                      decimalSeparator={DEFAULT_DECIMAL_SEPARATOR}
                      label={MESSAGES.minValue}
                      variant="outlined"
                      fullWidth
                      name="minValue"
                      value={field.minValue ?? ''}
                      disabled={isReadOnly}
                      onValueChange={(value) =>
                        handleNumberValueInputChange(
                          'minValue',
                          value.floatValue
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberFormatInput
                      customInput={TextField}
                      thousandSeparator={DEFAULT_THOUSAND_SEPARATOR}
                      decimalSeparator={DEFAULT_DECIMAL_SEPARATOR}
                      label={MESSAGES.maxValue}
                      variant="outlined"
                      fullWidth
                      name="maxValue"
                      value={field.maxValue ?? ''}
                      disabled={isReadOnly}
                      onValueChange={(value) =>
                        handleNumberValueInputChange(
                          'maxValue',
                          value.floatValue
                        )
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  name="info"
                  label={MESSAGES.info}
                  multiline={true}
                  rowsMax={5}
                  fullWidth
                  variant="outlined"
                  value={field.info ?? ''}
                  onChange={handleInputChange}
                  disabled={isReadOnly}
                />
              </Grid>
              {field.type === 'select' && (
                <Grid item xs={12}>
                  <SelectField
                    field={field}
                    formId={formId}
                    stepId={stepId}
                    dispatch={dispatch}
                  />
                </Grid>
              )}
              {field.type === 'groupedSelect' && (
                <Grid item xs={12}>
                  <GroupedSelectField
                    field={field}
                    formId={formId}
                    stepId={stepId}
                    dispatch={dispatch}
                  />
                </Grid>
              )}
              {field.type === 'formula' && (
                <Grid item xs={12}>
                  <FormulaAutocomplete
                    options={options}
                    value={field.expression ?? ''}
                    onChange={(value) => handleChange('expression', value)}
                  />
                </Grid>
              )}
              {field.type === 'multiSelect' && (
                <Grid item xs={12}>
                  <MultiSelectField
                    field={field}
                    formId={formId}
                    stepId={stepId}
                    dispatch={dispatch}
                  />
                </Grid>
              )}
              {field.type === 'select' && (
                <Grid item xs={12}>
                  <FormulaAutocomplete
                    options={options}
                    label={MESSAGES.additionalValuesFormula}
                    value={field.additionalValuesFormula ?? ''}
                    onChange={(value) =>
                      handleChange('additionalValuesFormula', value)
                    }
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <FormulaAutocomplete
                  options={options}
                  label={MESSAGES.formulaShowField}
                  value={field.expressionShow ?? ''}
                  onChange={(value) => handleChange('expressionShow', value)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormulaAutocomplete
                  options={options}
                  label={MESSAGES.validationFormula}
                  value={field.validationFormula ?? ''}
                  onChange={(value) => handleChange('validationFormula', value)}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.cardActions}>
            {!isReadOnly && (
              <DeleteButton onClick={() => removeField(field.id)} />
            )}
          </CardActions>
        </Collapse>
      </Card>
    </>
  );
};

export default React.memo(FieldBlock);
