import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Parser } from 'hot-formula-parser';
import moment from 'moment';
import React from 'react';
import { IS_DEBUG } from '../constants/config';
import { MESSAGES } from '../constants/messages';
import Form from '../models/form';
import ReportGroup from '../models/reportGroup';
import ExportButton from './export/ExportButton';
import SendEmailButton from './export/SendEmailButton';
import ReportRow from './ReportRow';

type Props = {
  form: Form;
  parser: Parser;
};

const ReportBlock: React.FC<Props> = ({ form, parser }) => {
  const reportGroups = form.reportGroups.map((group) => {
    return {
      group,
      derivedFields: calculateDerivedFields(parser, group),
    };
  });

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ExportButton />
        </Grid>
        <Grid item xs={6}>
          <SendEmailButton />
        </Grid>
      </Grid>
      {reportGroups && reportGroups.length > 0 && (
        <Box id="report-table">
          {form.report && form.report.title && (
            <Box mb={2}>
              <Typography variant="h6" component="h2">
                {form.report.title ?? form.report.title}
              </Typography>
            </Box>
          )}
          {reportGroups.map(
            (reportGroup) =>
              reportGroup.derivedFields &&
              reportGroup.derivedFields.length > 0 && (
                <Box mb={3} key={reportGroup.group.id}>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>
                            {reportGroup.group.name}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportGroup.derivedFields.map((field, index) => (
                          <ReportRow field={field} key={index} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )
          )}
          {form.report && form.report.disclaimer && (
            <>
              <Typography variant="h6" component="h3">
                {MESSAGES.disclaimer}
              </Typography>
              <Box mb={2} style={{ whiteSpace: 'pre-wrap' }}>
                {form.report.disclaimer}
              </Box>
            </>
          )}

          <Typography>
            {MESSAGES.calculationDate} {moment().format('DD-MM-YYYY')}
          </Typography>
        </Box>
      )}
      {IS_DEBUG && <pre>{JSON.stringify(parser.variables, null, 4)}</pre>}
    </Box>
  );
};

function calculateDerivedFields(parser: Parser, group: ReportGroup) {
  const derivedFields = group.formulas.map((formula) => {
    const value = parser.parse(formula.expression);

    parser.setVariable(formula.name, value.result);

    return {
      formula,
      value,
    };
  });

  return derivedFields;
}

export type DerivedFields = ReturnType<typeof calculateDerivedFields>;

export default ReportBlock;
