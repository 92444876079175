import { Box, Button } from '@material-ui/core';
import html2pdf from 'html2pdf.js';
import React from 'react';
import { ALERT_MESSAGES, MESSAGES } from '../../constants/messages';
import { options } from '../../constants/report';
import { logError } from '../../helpers/helpers';
import { useAlerts } from '../../hooks/useAlerts';

const ExportButton = () => {
  const [Alerts, setAlert] = useAlerts({
    success: ALERT_MESSAGES.exportSucces,
    info: ALERT_MESSAGES.exportInfo,
    error: ALERT_MESSAGES.exportError,
  });

  const handleClick = async () => {
    setAlert('info', true);

    try {
      await printDocument();
      setAlert('success', true);
    } catch {
      setAlert('error', true);
    }
  };

  return (
    <>
      <Box mb={3}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleClick}
        >
          {MESSAGES.exportPdf}
        </Button>
      </Box>
      <Alerts />
    </>
  );
};

async function printDocument() {
  try {
    const table = window.document.getElementById('report-table') as HTMLElement;

    await html2pdf().set(options).from(table).save();
  } catch (error) {
    throw logError(error);
  }
}

export default ExportButton;
