import {
  Box,
  Card,
  CardActions,
  CardContent,
  SwipeableDrawer,
  TextField,
} from '@material-ui/core';
import React, {
  createContext,
  Dispatch,
  useMemo,
  useState,
  useContext,
} from 'react';
import { Container, Draggable, DropResult } from 'react-smooth-dnd';
import { MESSAGES } from '../../constants/messages';
import { isEmpty, isMobile } from '../../helpers/helpers';
import Form from '../../models/form';
import { Action } from '../../reducers/formsReducer';
import { AddButton } from './AddButton';
import { DeleteButton } from './DeleteButton';
import { EditButton } from './EditButton';
import { Option } from './FormulaAutocomplete';
import ReportBlock from './ReportBlock';
import StepBlock from './StepBlock';
import { useCardClasses } from './Styled';
import { Title } from './Title';
import { IsReadOnlyContext, TokenContext } from '../../pages/Admin';

type Props = {
  form: Form;
  dispatch: Dispatch<Action>;
};

export const OptionsContext = createContext<Option[]>([]);

const FormBlock: React.FC<Props> = ({ form, dispatch }) => {
  const [isStepsOpen, setIsStepsOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const classes = useCardClasses();
  const isReadOnly = useContext(IsReadOnlyContext);
  const token = useContext(TokenContext);

  const openReport = () => setIsReportOpen(true);
  const closeReport = () => setIsReportOpen(false);

  const removeForm = (id: string) =>
    dispatch({ type: 'REMOVE_FORM', payload: { formId: id, token } });

  const updateForm = (id: string, name: string) =>
    dispatch({ type: 'UPDATE_FORM', payload: { formId: id, name } });

  const openSteps = () => setIsStepsOpen(true);
  const closeSteps = () => setIsStepsOpen(false);

  const addStep = () =>
    dispatch({ type: 'ADD_STEP', payload: { formId: form.id } });

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    updateForm(form.id, event.target.value);

  const onDrop = (
    { removedIndex, addedIndex }: DropResult,
    type: 'REORDER_STEP'
  ) =>
    dispatch({
      type,
      payload: {
        formId: form.id,
        removedIndex: removedIndex as number,
        addedIndex: addedIndex as number,
      },
    });

  const onStepDrop = (result: DropResult) => onDrop(result, 'REORDER_STEP');

  const options = useMemo(
    () =>
      form.steps.flatMap((step) =>
        step.fields.map((field) => ({
          title: field.name,
          type: MESSAGES.fields,
        }))
      ),
    [form]
  );

  return (
    <OptionsContext.Provider value={options}>
      <Card className={classes.card}>
        <CardContent>
          <TextField
            fullWidth
            variant="outlined"
            label={MESSAGES.name}
            value={form.name}
            onChange={onNameChange}
            disabled={isReadOnly}
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <EditButton
            label={MESSAGES.steps}
            icon="format_list_numbered"
            onClick={openSteps}
          />
          <EditButton label={MESSAGES.report} onClick={openReport} />
          {!isReadOnly && <DeleteButton onClick={() => removeForm(form.id)} />}
        </CardActions>
      </Card>

      <SwipeableDrawer
        anchor="right"
        open={isStepsOpen}
        onClose={closeSteps}
        onOpen={openSteps}
      >
        <Box p={2} style={{ width: !isMobile() ? '80vw' : 'auto' }}>
          <Title
            onClick={closeSteps}
            title={`${form.name} - ${MESSAGES.steps}`}
          />
          {!isEmpty(form.steps) ? (
            <Container
              dragHandleSelector=".drag-handle"
              lockAxis="y"
              onDrop={onStepDrop}
            >
              {form.steps.map((step) => (
                <Draggable key={step.id}>
                  <StepBlock step={step} formId={form.id} dispatch={dispatch} />
                </Draggable>
              ))}
            </Container>
          ) : (
            <Box pb={5}>{MESSAGES.noSteps}</Box>
          )}

          {!isReadOnly && <AddButton onClick={addStep} />}
        </Box>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={isReportOpen}
        onClose={closeReport}
        onOpen={openReport}
      >
        <Box p={2} style={{ width: !isMobile() ? '80vw' : 'auto' }}>
          <Title
            onClick={closeReport}
            title={`${form.name} - ${MESSAGES.reportGroups}`}
          />
          <ReportBlock form={form} dispatch={dispatch} />
        </Box>
      </SwipeableDrawer>
    </OptionsContext.Provider>
  );
};

export default React.memo(FormBlock);
