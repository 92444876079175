import Data from '../models/data';
import Step from '../models/step';
import Form from '../models/form';
import { Fields } from '../models/field';

export const fieldsStep1: Fields = [
  {
    id: '1',
    name: 'txtEmployeeName',
    type: 'text',
    label: 'Naam van de medewerker',
    defaultValue: 'Michel Croiset',
    validationFormula: 'txtEmployeeName="Michel Croiseta"',
  },
  {
    id: '1123123',
    name: 'table',
    type: 'multiSelect',
    label: 'Multiselect',
    values: ['value 1', 'value 2'],
  },
  {
    id: '2',
    name: 'dtBirthdateValue',
    type: 'date',
    label: 'Geboortedatum van de medewerker',
    placeholder: 'placeholder',
    defaultValue: '2000-01-01',
    validationFormula:
      'IF(dtBirthdateValue = "2000-01-01", "Custom error message", TRUE)',
  },
  {
    id: '21233',
    name: 'dtBirthdatePlaceholder',
    type: 'date',
    label: 'Geboortedatum van de medewerker',
    placeholder: 'placeholder',
  },
  {
    id: '12312312312',
    name: 'dtBirthdateNoValNoPlaceholder',
    type: 'date',
    label: 'Geboortedatum van de medewerker',
  },
  {
    id: '3',
    name: 'selEmploymentType',
    type: 'select',
    label: 'Soort arbeidsovereenkomst',
    defaultValue: 'vast',
    values: ['vast', 'tijdelijk', 'vul hier het soort arbeidsovereenkomst in'],
    validationFormula: 'IF(selEmploymentType = "vast", "Value is vast", TRUE)',
  },
  {
    id: '35123123',
    name: 'selEmploymentType2',
    type: 'text',
    label: 'Soort arbeidsovereenkomst',
    placeholder: 'vast',
  },
  {
    id: '351223123',
    name: 'selEmploymentType3',
    type: 'number',
    label: 'Soort arbeidsovereenkomst',
    placeholder: 'vast',
  },
  {
    id: '4',
    name: 'selBakery',
    type: 'select',
    label: 'Bakkerij',
    defaultValue: 'Industriële bakkerij',
    values: [
      'Industriële bakkerij',
      'Ambachtelijke bakkerij',
      'Vul hier de bakkerij in waarvoor je gaat werken of waarin je werkzaam bent',
    ],
  },
];

export const fieldsStep2: Fields = [
  {
    id: '5',
    name: 'numField1',
    type: 'number',
    label: 'Number 1',
    minValue: -100,
    maxValue: 100,
  },
  {
    id: '6',
    name: 'dtWorksFrom',
    type: 'date',
    label: 'Works from',
    defaultValue: '2020-01-01',
  },
  {
    id: '7',
    name: 'dtWorksTill',
    type: 'date',
    label: 'Works till',
    defaultValue: '',
  },
  {
    id: '8',
    name: 'setPositionType',
    type: 'select',
    label: 'Position',
    defaultValue: 'None',
    values: ['None', 'Position 1', 'Position 2', 'Position 3'],
  },
];

export const fieldsStep3: Fields = [
  {
    id: '9',
    name: 'txtText1',
    type: 'text',
    label: 'Text 1',
    defaultValue: 'Enter value',
  },
  {
    id: '10',
    name: 'selSelect1',
    type: 'select',
    label: 'Select',
    defaultValue: 'None',
    values: ['None', 'Value 1', 'Value 2', 'Value 3'],
  },
];

export const step1: Step = {
  id: '123456789-1',
  name: 'Persoonlijke gegevens',
  fields: fieldsStep1,
};

export const step2: Step = {
  id: '123456789-2',
  name: 'Functiegegevens',
  fields: fieldsStep2,
};

export const step3: Step = {
  id: '123456789-3',
  name: 'Functiegegevens 3',
  fields: fieldsStep3,
};

export const form: Form = {
  id: '1336',
  name: 'Ik ben vakantiewerker',
  isDirty: false,
  steps: [
    {
      id: '123456789-1',
      name: 'Stap 1 - Basisgegevens',
      fields: [
        {
          id: '1',
          name: 'txtEmployeeName',
          type: 'text',
          label: 'Wat is je naam?',
          defaultValue: 'Michel Croiset',
          info:
            'Vul hier je voor en achternaam in. <a href="esoftware.solutions" rel="noreferrer noopener" target="_blank">external link</a>',
          validationFormula: 'txtEmployeeName="Michel Croiseta"',
        },
        {
          id: '2',
          name: 'dtBirthdate',
          type: 'date',
          label: 'Geboortedatum?',
          defaultValue: '1991-01-01',
          info:
            'Vul hier je geboortedatum in zodat de juiste regelingen zichtbaar voor jouw situatie zichtbaar zijn.',
        },
        {
          id: '3',
          name: 'selEmploymentType',
          type: 'select',
          label: 'Soort arbeidsovereenkomst',
          defaultValue: 'vast',
          values: [
            'vul hier het soort arbeidsovereenkomst in',
            'tijdelijk',
            'vast',
          ],
        },
        {
          id: '4',
          name: 'selBakery',
          type: 'select',
          label: 'Bakkerij',
          defaultValue: 'Industriële bakkerij',
          values: [
            'Vul hier de bakkerij in waarvoor je gaat werken of waarin je werkzaam bent',
            'Ambachtelijke bakkerij',
            'Industriële bakkerij',
          ],
        },
      ],
      title: 'Stap 1. Vul hier je eigen gegevens in',
      description:
        'Via dit online loonberekeningsprogramma kunt je een berekening uitvoeren op basis van het bruto CAO-loon dat hoort bij de functie van de medewerker. Door het invullen van je eigen gegevens komt er een gepersonaliseerde berekening uit.',
      disclaimer:
        '*persoonlijke informatie wordt nergens zonder toestemming opgeslagen',
    },
    {
      id: '123456789-2',
      name: 'Stap 2 - Functiegegevens',
      fields: [
        {
          id: '5',
          name: 'selJobGroups',
          type: 'groupedSelect',
          label: 'CAO functieindeling',
          defaultValue: 'A.06 Medewerker facilitaire dienst',
          groups: [
            {
              id: '0b4eb0a1-079c-43b7-8934-0ad3489daf08',
              name: 'Functiegroep algemeen',
              values: [
                'A.06 Medewerker facilitaire dienst',
                'A.04 Telefonist(e)/receptionist(e)',
                'A.01 Medewerker HRM',
                'A.05 Secretaresse I',
                'A.05 Secretaresse II',
                'A.05 Secretaresse III',
                'A.02 Personeelsfunctionaris',
                'A.03 Manager HR',
              ],
            },
            {
              id: '08f37271-1f09-4035-8fe0-b8dc30347990',
              name: 'Functiegroep financieel administratief',
              values: [
                'F.02 Administratief medewerker I',
                'F.02 Administratief medewerker II',
                'F.03 Medewerker crediteurenadministratie I',
                'F.03 Medewerker crediteurenadministratie II',
                'F.03 Medewerker crediteurenadministratie III',
                'F.04 Medewerker debiteurenadministratie I',
                'F.04 Medewerker debiteurenadministratie II',
                'F.04 Medewerker debiteurenadministratie III',
                'F.05 Medewerker financiële administratie I',
                'F.05 Medewerker financiële administratie II',
                'F.05 Medewerker financiële administratie III',
                'F.07 Medewerker salarisadministratie',
                'F.06 Administrateur I',
                'F.06 Administrateur II',
                'F.06 Administrateur III',
                'F.01 Manager Financiën I',
                'F.01 Manager Financiën II',
                'F.01 Manager Financiën III boven-CAO',
              ],
            },
            {
              id: 'be96972b-f8d7-47bf-9b61-6218265b3319',
              name: 'Functiegroep ict',
              values: [
                'I.04 Helpdeskmedewerker',
                'I.03 Systeembeheerder I',
                'I.03 Systeembeheerder II',
                'I.03 Systeembeheerder III',
                'I.02 Applicatiebeheerder/analist',
                'I.01 Manager ICT',
              ],
            },
            {
              id: '0fbb10cb-0608-4e95-a266-d4b2f6f2df2b',
              name: 'Functiegroep kwaliteit & productontwikkeling',
              values: [
                'K.03 Kwaliteitsanalist',
                'K.02 Productontwikkelaar',
                'K.04 KAM specialist',
                'K.01 Hoofd kwaliteit en productontwikkeling',
              ],
            },
            {
              id: '3e2294a7-bf0f-4a7e-9bc3-5c73dd52398c',
              name: 'Functiegroep logistiek',
              values: [
                'L.06 Verdeler I',
                'L.06 Verdeler II',
                'L.02 Magazijnmedewerker I',
                'L.02 Magazijnmedewerker II',
                'L.02 Magazijnmedewerker III',
                'L.01 Chauffeur I',
                'L.01 Chauffeur II',
                'L.03 Chef magazijn I',
                'L.03 Chef magazijn II',
                'L.03 Chef magazijn III',
                'L.05 Medewerker bedrijfsbureau I',
                'L.05 Medewerker bedrijfsbureau II',
                'L.05 Medewerker bedrijfsbureau III',
                'L.04 Inkoper',
              ],
            },
            {
              id: '1f6b7181-51cc-46a8-8bd1-e93373d2b381',
              name: 'Functiegroep productie',
              values: [
                'P.09 Algemeen medewerker productie/inpak',
                'P.02 Assistent ambachtelijke bakkerij I',
                'P.02 Assistent ambachtelijke bakkerij II',
                'P.07 Productiemedewerker I',
                'P.07 Productiemedewerker II',
                'P.05 Operator inpak I',
                'P.05 Operator inpak II',
                'P.05 Operator inpak III',
                'P.01 Broodbakker I',
                'P.01 Broodbakker II',
                'P.01 Broodbakker III',
                'P.03 Banketbakker I',
                'P.03 Banketbakker II',
                'P.03 Banketbakker III',
                'P.06 Operator productie I',
                'P.06 Operator productie II',
                'P.06 Operator productie III',
                'P.04 Teamleider productie/inpak I',
                'P.04 Teamleider productie/inpak II',
                'P.04 Teamleider productie/inpak III',
                'P.08 Manager productie I',
                'P.08 Manager productie II',
                'P.08 Manager productie III',
              ],
            },
            {
              id: '6ba386be-e551-494d-bb8e-45817e489319',
              name: 'Functiegroep technische dienst',
              values: [
                'T.02 Medewerker TD I',
                'T.02 Medewerker TD II',
                'T.02 Medewerker TD III',
                'T.01 Hoofd TD I',
                'T.01 Hoofd TD II',
                'T.01 Hoofd TD III',
              ],
            },
            {
              id: '998da462-6fd5-4dcd-9132-d09b25cdc5fb',
              name: 'Functiegroep Commercie',
              values: [
                'C.01 Verkoopmedewerker I loonschaal',
                'C.01 Verkoopmedewerker II loonschaal',
                'C.01 Verkoopmedewerker III loonschaal',
                'C.08 Bezorger loonschaal',
                'C.07 Medewerker verkoop binnendienst loonschaal',
                'C.02 Bedrijfs-/filiaalleider I loonschaal',
                'C.02 Bedrijfs-/filiaalleider II loonschaal',
                'C.02 Bedrijfs-/filiaalleider III loonschaal',
                'C.04 Winkel accountmanager loonschaal',
                'C.03 Regiomanager loonschaal',
                'C.05 Key accountmanager loonschaal',
                'C.06 Formule specialist loonschaal',
              ],
            },
          ],
        },
        {
          id: '7',
          name: 'txtPayRate',
          type: 'formula',
          label: 'Loonschaal',
          expressionShow: '',
          expression: 'VLOOKUP(selJobGroups, FunctiegroepAlgemeenTable, 2)',
        },
        {
          id: '8',
          name: 'selNumberJobYears',
          type: 'select',
          label: 'Aantal functiejaren of periodieken',
          defaultValue: '1',
          values: [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '15+',
          ],
          additionalValuesFormula:
            'CONCATENATE("{value}.", " Bruuto uurloon: ", INDEX(FunctiejarenTabel, {value} + 1, txtPayRate + 1))',
        },
        {
          id: 'ccfd855a-1c68-4b03-9a07-e27536287359',
          name: 'numGrossHourlyWage',
          label: 'Bruto uurloon',
          type: 'formula',
          expressionShow: '',
          defaultValue: '0',
          expression:
            'INDEX(FunctiejarenTabel,selNumberJobYears+1,txtPayRate+1)',
        },
        {
          id: '7b5258f6-6242-404f-8f1f-9ee1da158837',
          name: 'selVitalityScheme',
          label: 'Vitaliteitsregeling van toepassing?',
          type: 'select',
          defaultValue: 'Ja',
          values: ['Ja', 'Nee'],
          expressionShow: 'YEARFRAC(dtBirthdate, TODAY()) >= 60',
        },
        {
          id: '912a32ed-4901-4b3c-a212-ff70e0a6d782',
          name: 'selVitalitySchemeOptional',
          label: 'Vitaliteitsregeling van toepassing?',
          type: 'select',
          expressionShow: 'YEARFRAC(dtBirthdate, TODAY()) < 60',
          values: [
            'Ja, ik wil er toch mee rekenen',
            'Nee, Op basis van je ingevulde geboortedatum kom je niet in aanmerking voor de vitaliteitsregeling',
          ],
          defaultValue:
            'Nee, Op basis van je ingevulde geboortedatum kom je niet in aanmerking voor de vitaliteitsregeling',
        },
        {
          id: 'f7a9d3ab-a4b1-4cb1-9458-121651df4f8c',
          name: 'numContractures',
          label: 'Contracturen',
          type: 'number',
          defaultValue: 38,
          maxValue: 38,
          minValue: 1,
          info: 'Maximeren op 38 uur',
        },
        {
          id: '83b2553d-88bf-447a-a6d6-9b2829056393',
          name: 'selPayPeriod',
          label: 'Loonperiode',
          type: 'select',
          values: ['week', '4-weken', 'maand'],
          defaultValue: 'maand',
        },
        {
          id: '2f9ae842-db4a-4ff3-a7bc-21603a6fe802',
          name: 'selHolidayAllowanceEveryPayPeriod',
          label: 'Krijg je de vakantietoeslag iedere loonperiode uitbetaald?',
          type: 'select',
          defaultValue: 'Ja',
          values: ['Ja'],
        },
        {
          id: 'c5e16002-fd56-4f95-836e-b9ad94baf8de',
          name: 'txtOldHours',
          label: 'Oude uren',
          type: 'formula',
          expressionShow: '',
          expression: '(numContractures/8)*10',
        },
        {
          id: '63028cc4-3c9c-4b2d-b34f-3545db900e7a',
          name: 'txtVitalityControl',
          label: 'Vitaliteitsregeling (90%)',
          type: 'formula',
          defaultValue: '0',
          expressionShow: '',
          expression: 'txtOldHours*0.9',
        },
      ],
      title: 'Stap 2. Vul hier je gegevens met betrekking tot je functie in.',
    },
    {
      id: '51ca1da4-523e-45b9-9c4c-23fba6cfbb99',
      name: 'Stap 3 - Toeslagen & overig',
      fields: [
        {
          id: '810a5047-40d5-4f34-9f71-d1b565abfe13',
          name: 'selWorkHours',
          label: 'Werk je tussen 18:00 en 06:00',
          type: 'select',
          defaultValue: 'Nee',
          values: ['Ja', 'Nee'],
        },
        {
          id: '7fccdb27-d048-413f-bac4-38b0dacdc388',
          name: 'selOvertimeHoursFirst',
          label: 'Categorie',
          type: 'select',
          defaultValue: '',
          expressionShow: '',
          values: [
            'Ma-za tussen 0.00 en 04.00 uur (37%)',
            'Ma-za tussen 04.00 en 06.00 uur (34%)',
            'Ma-za tussen 18.00 en 24.00 uur (34%)',
          ],
        },
        {
          id: 'c7e3b244-27ea-4b17-bc88-80e05400f24f',
          name: 'numOvertimeHoursFirst',
          label: 'Toeslaguren',
          type: 'number',
          defaultValue: 0,
        },
        {
          id: '8d2491f8-9b79-462d-98df-9ad169c3920a',
          name: 'selOvertimeHoursSecond',
          label: 'Categorie',
          type: 'select',
          defaultValue: '',
          values: [
            'Ma-za tussen 0.00 en 04.00 uur (37%)',
            'Ma-za tussen 04.00 en 06.00 uur (34%)',
            'Ma-za tussen 18.00 en 24.00 uur (34%)',
          ],
        },
        {
          id: '4e4322e5-fbf0-452a-91e6-1fed4f78e68e',
          name: 'numOvertimeHoursSecond',
          label: 'Toeslaguren',
          type: 'number',
          defaultValue: 0,
        },
        {
          id: 'b3e1d310-9d11-42b0-a992-369d593a987e',
          name: 'selWorkOnWeekends',
          label: 'Werk je in het weekend',
          type: 'select',
          defaultValue: 'Nee',
          values: ['Ja', 'Nee'],
        },
        {
          id: '8d2491f8-9b79-462d-98df-9ad169c3920c',
          name: 'selOvertimeHoursOnWeekends',
          label: 'Categorie',
          type: 'select',
          defaultValue: '',
          values: [
            'Ma-za tussen 04.00 en 18.00 uur (34%)',
            'Ma-za tussen 18.00 en 24.00 uur (100%)',
            'Ma-za tussen 0.00 en 24.00 uur (100%)',
          ],
        },
        {
          id: '1b22b950-f1bf-4e89-85d5-c917806d1427',
          name: 'numOvertimeHoursOnWeekends',
          label: 'Toeslaguren',
          type: 'number',
          defaultValue: 0,
        },
        {
          id: 'b8c2a8f5-07ec-4189-9f39-8fbc1a317666',
          name: 'selWorkInColdStore',
          label: 'Werk je in een koelcel?',
          type: 'select',
          defaultValue: 'Nee',
          values: [
            'Nee',
            'Ja (0-10 uur per week)',
            'Ja (10-20 uur per week)',
            'Ja (>20 uur per week)',
          ],
        },
        {
          id: 'd106cce9-2047-4866-a4af-4e7e941eed04',
          name: 'selEmergencyResponseOfficer',
          label: 'Ben je bedrijfshulpverlener?',
          type: 'select',
          defaultValue: 'Nee',
          values: ['Ja', 'Nee'],
        },
        {
          id: '57c43ca9-026d-4d8d-a14d-ee6a894f4d79',
          name: 'selReceiveSupplementForUsingClothing',
          label: 'Ontvang je toeslag voor het gebruik bedrijfskleding?',
          type: 'select',
          defaultValue: 'Nee',
          values: ['Ja', 'Nee'],
        },
        {
          id: '0ecf14eb-ba74-43b3-bb98-37f6c2c12930',
          name: 'selPersonalSuplement',
          label: 'Ontvang je persoonlijke toeslag?',
          type: 'select',
          values: ['Ja', 'Nee'],
          defaultValue: 'Nee',
        },
        {
          id: '570fb574-053f-452d-9517-8d86e05d7adc',
          name: 'numPersonalSuplement',
          label: 'Persoonlijke toeslag per week',
          type: 'number',
          defaultValue: 0,
          expressionShow:
            'IF(selPersonalSuplement="Ja", IF(selPayPeriod="week", TRUE, FALSE), FALSE)',
        },
        {
          id: '60296178-97fd-4564-98bc-b58e08131587',
          name: 'numPersonalSuplement',
          label: 'Persoonlijke toeslag per maand',
          type: 'number',
          defaultValue: 0,
          expressionShow:
            'IF(selPersonalSuplement="Ja", IF(selPayPeriod="maand", TRUE, FALSE), FALSE)',
        },
        {
          id: 'c8caffbd-36ab-47e3-b255-a397a20a4c4e',
          name: 'numPersonalSuplement',
          label: 'Persoonlijke toeslag per 4-weken',
          type: 'number',
          defaultValue: 0,
          expressionShow:
            'IF(selPersonalSuplement="Ja", IF(selPayPeriod="4-weken", TRUE, FALSE), FALSE)',
        },
        {
          id: '6d5294de-8b1c-427a-a685-61aa2bf174df',
          name: 'selCompanyCar',
          label: 'Auto van de zaak',
          type: 'select',
          defaultValue: 'Nee',
          values: ['Ja', 'Nee'],
        },
        {
          id: '21f0d220-26df-497b-82d9-00d24378f628',
          name: 'numCategoryValue',
          label: 'Cat. Waarde',
          type: 'number',
          defaultValue: 0,
          expressionShow: 'IF(selCompanyCar="Ja", TRUE, FALSE)',
        },
        {
          id: '08deaac0-2dc9-4abc-8f01-03f12f3e882d',
          name: 'numAdditionalPercentage',
          label: 'Bijtellingspercentage',
          type: 'number',
          defaultValue: 0,
          expressionShow: 'IF(selCompanyCar="Ja", TRUE, FALSE)',
        },
        {
          id: '0db2779d-1904-4ee2-a0a2-e471544bf2f8',
          name: 'numContribution',
          label: 'Eigen bijdrage',
          type: 'number',
          expressionShow: 'IF(selCompanyCar="Ja", TRUE, FALSE)',
          defaultValue: 0,
        },
        {
          id: '1598e378-402e-481d-8145-75688cc9158e',
          name: 'numContributionNotDeductible',
          label: 'Eigen bijdrage (niet aftrekbaar)',
          type: 'number',
          defaultValue: 0,
          expressionShow: 'IF(selCompanyCar="Ja", TRUE, FALSE)',
        },
        {
          id: '9b0c29e1-26e8-412c-a70c-368f3897b754',
          name: 'selGapInsurance',
          label: 'WGA-Hiaatverzekering',
          type: 'select',
          defaultValue: 'Nee',
          values: ['Ja, op basis van een andere verzekeraar', 'Nee'],
        },
        {
          id: '3c6e4347-e74e-438a-9949-3e2b30e01a89',
          name: 'numPercentageOfInsurer',
          label: 'Vul hier het percentage van je verzekeraar in',
          type: 'number',
          defaultValue: 0,
          expressionShow:
            "IF(selGapInsurance='Ja, op basis van een andere verzekeraar', TRUE, FALSE)",
        },
        {
          id: '312f58d1-74cd-418c-bc61-ebc90d70bef6',
          name: 'selPayrollTaxCredit',
          label: 'Loonheffingskorting?',
          type: 'select',
          values: ['Ja', 'Nee'],
          defaultValue: 'Nee',
        },
      ],
      title: 'Stap 3. Vul hier de gevraagde gegevens in.',
    },
  ],
  reportGroups: [
    {
      id: '1c6ab178-8c24-42bf-a701-adf3d35cc0e6',
      name: 'Basisgegevens',
      formulas: [
        {
          id: '12f0bdb6-72ec-4347-9bd4-fcb030031248',
          label: 'Wat is je naam?',
          expression: 'txtEmployeeName',
          name: 'employeeName',
        },
        {
          id: '03fdde9d-f686-402d-84a3-d05d64c5424a',
          label: 'Geboortedatum?',
          expression: 'dtBirthdate',
          name: 'birthDate',
        },
        {
          id: 'd5f3bcb7-5273-461d-a0fd-64d11005e0b1',
          label: 'Bakkerij',
          expression: 'selBakery',
          name: 'bakery',
        },
      ],
    },
    {
      id: '3896a247-00b5-4f75-bc7e-ce85e87ce9fe',
      name: 'Branchespecifieke gegevens',
      formulas: [
        {
          id: 'e535f60d-392d-43d8-bb11-ca044ceb923e',
          label: 'Vitaliteitsregeling van toepassing?',
          expression:
            'IF(YEARFRAC(dtBirthdate, TODAY()) >= 60, selVitalityScheme, selVitalitySchemeOptional)',
          name: 'vitalityScheme',
        },
        {
          id: '1231231223',
          label: 'Soort arbeidsovereenkomst',
          expression: 'selEmploymentType',
          name: 'employmentType',
        },
        {
          id: '1',
          label: 'Contracturen',
          expression: 'numContractures',
          name: 'contractures',
        },
        {
          id: '6f97c44e-b28a-425e-885f-8b6f6a3a939b',
          label: 'Loonperiode',
          expression: 'selPayPeriod',
          name: 'payPeriod',
        },
        {
          id: '7828e200-b5b1-46fc-b972-704581b6d802',
          label: 'Periodieke uitbetaling vakantietoeslag',
          expression: 'selHolidayAllowanceEveryPayPeriod',
          name: 'holidayAllowanceEveryPayPeriod',
        },
        {
          id: '7e2374ad-dd0f-45bc-837a-5f5c160e51ee',
          label: 'CAO functieindeling',
          expression: 'selJobGroups',
          name: 'jobGroup',
        },
        {
          id: '9d6f4b52-eb3b-4a0c-927a-918b80b42ff8',
          label: 'Loonschaal',
          expression: 'txtPayRate',
          name: 'payRate',
        },
        {
          id: 'f0bcb135-dea1-4dce-b885-93e4f8c392f0',
          label: 'Aantal functiejaren of periodieken',
          expression: 'selNumberJobYears',
          name: 'numberJobYears',
        },
        {
          id: 'de0f7c8e-2b3d-442d-9bcd-8303f858ef53',
          label: 'Bruto uurloon',
          expression: 'numGrossHourlyWage',
          name: 'grossHourlyWage',
        },
      ],
    },
    {
      id: '99f4aa66-e3cc-4f39-809a-8307daa9b097',
      name: 'Toeslagen',
      formulas: [
        {
          id: '592d83e7-738c-4f66-abbf-e464f812b9b3',
          label: 'Categorie',
          expression: 'selOvertimeHoursFirst',
          name: 'categorieOvertimeHoursFirstName',
        },
        {
          id: '1',
          label: 'Toeslaguren',
          expression: '(numOvertimeHoursFirst*52)/12',
          name: 'categorieOvertimeHoursFirstValue',
        },
        {
          id: '8395039f-3416-41d5-8122-1aeb85e57a79',
          label: 'Categorie',
          expression: 'selOvertimeHoursSecond',
          name: 'categorieOvertimeHoursSecondName',
        },
        {
          id: '4',
          label: 'Toeslaguren',
          expression: '(numOvertimeHoursSecond*52)/12',
          name: 'categorieOvertimeHoursSecondValue',
        },
        {
          id: '6c05dd91-18b8-4389-8c45-f6877121acc8',
          label: 'Categorie',
          expression: 'selOvertimeHoursOnWeekends',
          name: 'categorieOvertimeHoursOnWeekendsName',
        },
        {
          id: '7828e200-b5b1-46fc-b972-704581b6d802',
          label: 'Toeslaguren',
          expression: '(numOvertimeHoursOnWeekends*52)/12',
          name: 'categorieOvertimeHoursOnWeekendsValue',
        },
        {
          id: 'd28c261c-0c24-42fb-baef-1a4a2d78b17c',
          label: 'Werk je in een koelcel?',
          expression: 'selWorkInColdStore',
          name: 'workInColdStore',
        },
        {
          id: '026485d3-0c71-459f-ae04-c2a6fa8c8b9f',
          label: 'Koelcel toeslag',
          expression:
            'IF(selWorkInColdStore = "Nee", 0 ,VLOOKUP(selWorkInColdStore, toeslagenIndustrieTable, 2, FALSE))',
          name: 'koelceltoeslag',
        },
        {
          id: '2005e08b-2102-4e34-9932-5cbab6665546',
          label: 'Ben je bedrijfshulpverlener?',
          expression: 'selEmergencyResponseOfficer',
          name: 'emergencyResponseOfficer',
        },
        {
          id: '08cd0369-b713-4fb4-bb53-5a2f35be1f66',
          label: 'Bedrijfshulpverlener toeslag',
          expression: 'IF(selEmergencyResponseOfficer = "Nee",  0, 4.39)',
          name: 'emergencyResponseOfficertoeslag',
        },
        {
          id: '7e606fdd-0519-488a-9a81-e3e710af3b99',
          label: 'Ontvang je toeslag voor het gebruik bedrijfskleding?',
          expression: 'selReceiveSupplementForUsingClothing',
          name: 'receiveSupplementForUsingClothing',
        },
        {
          id: '44fde0a9-05ee-4e24-88b7-8783e49a7cfa',
          label: 'Ontvang je toeslag voor het gebruik bedrijfskleding? Toeslag',
          expression:
            'IF(selReceiveSupplementForUsingClothing = "Nee", 0, 6.14)',
          name: 'receiveSupplementForUsingClothingToeslag',
        },
      ],
    },
    {
      id: 'b2a9051f-94c1-4838-bc2d-da8d2a7f111c',
      name: 'Persoonlijke situatie',
      formulas: [
        {
          id: 'fe5e1c11-01d2-4e36-91dc-200d2275f074',
          label: 'Persoonlijke toeslag per',
          expression: 'selPayPeriod',
          name: 'personalSuplementName',
        },
        {
          id: '99aff9dc-da2a-4bd6-8ef8-0c1b8856606b',
          label: 'Persoonlijke toeslag',
          expression: 'numPersonalSuplement',
          name: 'personalSuplement',
        },
        {
          id: 'e6608d41-537e-40d6-9dad-03b2f055b5f4',
          label: 'Auto van de zaak',
          expression: 'selCompanyCar',
          name: 'companyCar',
        },
        {
          id: 'b567fb4e-a879-41ec-95c8-f1beccda96bd',
          label: 'Cat. Waarde',
          expression: 'numCategoryValue',
          name: 'categoryValue',
        },
        {
          id: '1a84fce7-a8bb-4c4d-8407-a977abb6501c',
          label: 'Bijtellingspercentage',
          expression: 'numAdditionalPercentage',
          name: 'additionalPercentage',
        },
        {
          id: '2acd2e50-2f3e-41dc-b2c9-67484a1461f7',
          label: 'Eigen bijdrage',
          expression: '0',
          name: 'contribution',
        },
        {
          id: 'fbaac157-f95b-4d19-bd70-0ca2fb12002a',
          label: 'Eigen bijdrage (niet aftrekbaar)',
          expression: 'numContributionNotDeductible',
          name: 'contributionNotDeductible',
        },
        {
          id: 'bffccb44-d952-4c19-a715-17019dd154a9',
          label: 'Loonheffingskorting?',
          expression: 'selPayrollTaxCredit',
          name: 'payrollTaxCredit',
        },
      ],
    },
    {
      id: '2961ce86-5d3b-4192-b72f-de81850cb42b',
      name: 'Resultaten',
      formulas: [
        {
          id: '90d582fb-ccec-4b34-b030-5b2373bb276c',
          label: 'Functieloon:',
          expression: 'ROUND((contractures * 52 * grossHourlyWage) / 12, 2)',
          name: 'jobPay',
        },
        {
          id: '06fd17a3-e952-4d61-816c-c67059ca2c23',
          label: 'Toeslag onr.uren logistiek/productie/TD-personeel:',
          expression:
            'ROUND((grossHourlyWage * 0.37 * categorieOvertimeHoursFirstValue * 52) / 12, 2)',
          name: 'surchargeForHoursOfLogistics',
        },
        {
          id: '9e61d7af-ec1d-4b15-b030-1bd19e1f9b97',
          label: 'Toeslag onr.uren logistiek/productie/TD-personeel:',
          expression: 'ROUND((koelceltoeslag * 52) / 12, 2)',
          name: 'toeslaglogistiek',
        },
        {
          id: '0bd67319-1c43-4739-b111-428ad453eb82',
          label: 'Toeslag werken in koel/vriescel:',
          expression: 'ROUND((koelceltoeslag * 52) / 12, 2)',
          name: 'toeslagkoel',
        },
        {
          id: '7584d6ba-6ad1-4152-a73f-39e9f1fc49a8',
          label: 'Toeslag bedrijfshulpverlener:',
          expression: 'ROUND((emergencyResponseOfficertoeslag * 52) / 12, 2)',
          name: 'toeslagbedrijfshulpverlener',
        },
        {
          id: '3e0179b5-1f48-4955-ae2d-5d5fccdfda39',
          label: 'Persoonlijke toeslag:',
          expression: 'numPersonalSuplement',
          name: 'toeslagpersoonlijke',
        },
        {
          id: '448b3846-aecf-45b1-8da9-b787b9e5b230',
          label: 'Vakantietoeslag:',
          expression:
            'IF(selHolidayAllowanceEveryPayPeriod = "Ja", (jobPay + surchargeForHoursOfLogistics + toeslaglogistiek + toeslagkoel + toeslagbedrijfshulpverlener + toeslagpersoonlijke) * 0.08, 0)',
          name: 'vakantietoeslag',
        },
        {
          id: 'b8774291-4d9e-48df-b21b-ecbfe7ff6e86',
          label: 'Bijtelling auto:',
          expression:
            '((numCategoryValue * numAdditionalPercentage) / 12) - numContributionNotDeductible',
          name: 'bijtellingauto',
        },
        {
          id: '670fb732-7a76-4a96-a53a-fb91931ce453',
          label: 'PAWW (0,30%):',
          expression:
            '(jobPay + surchargeForHoursOfLogistics + toeslaglogistiek + toeslagkoel + toeslagbedrijfshulpverlener + toeslagpersoonlijke + bijtellingauto) * 0.3',
          name: 'paww',
        },
        {
          id: '7fe25d94-338a-45b0-aca9-eb2d37e4add3',
          label: 'WGA-Hiaatverzekering',
          expression:
            '(jobPay + surchargeForHoursOfLogistics + toeslaglogistiek + toeslagkoel + toeslagbedrijfshulpverlener + toeslagpersoonlijke + bijtellingauto) * 0',
          name: 'wga',
        },
        {
          id: 'ef992fd8-9cda-4158-80bd-61d6327560a5',
          label: 'Brutoloon:',
          expression:
            '(jobPay + surchargeForHoursOfLogistics + toeslaglogistiek + toeslagkoel + toeslagbedrijfshulpverlener + toeslagpersoonlijke + vakantietoeslag + bijtellingauto) - wga - paww',
          name: 'brutoloon',
        },
        {
          id: '78a460e0-8191-4308-90ad-5e271c45fe2f',
          label: 'Vitaliteitsloon (1,125 * Brutoloon):',
          expression:
            'IF(vitalityScheme = "Ja", brutoloon * 1.125, IF(vitalityScheme = "Ja, ik wil er toch mee rekenen", brutoloon * 1.125, ""))',
          name: 'vitaliteitsloon',
        },
        {
          id: '96ef45a9-448f-4d36-80c9-1c596a01de03',
          label: 'Pensioenpremie werknemer:',
          expression:
            'IF(vitaliteitsloon="",((brutoloon+(13785/(IF(payPeriod="maand",12,IF(payPeriod="4-weken",13,IF(payPeriod="week",52,"FOUT")))))*1*0.0889)/(1+0.0889)-(13785/(IF(payPeriod="maand",12,IF(payPeriod="4-weken",13,IF(payPeriod="week",52,"FOUT"))))))*0.0889,((vitaliteitsloon*1.111+(13785/(IF(payPeriod="maand",12,IF(payPeriod="4-weken",13,IF(payPeriod="week",52,"FOUT")))))*1*0.0889)/(1+0.0889)-(13785/(IF(payPeriod="maand",12,IF(payPeriod="4-weken",13,IF(payPeriod="week",52,"FOUT"))))))*0.08)',
          name: 'pensioenpremiewerknemer',
        },
        {
          id: 'e3e18c64-de30-4f49-b457-dca7563b2388',
          label: 'Uniform loon:',
          expression:
            'IF(vitaliteitsloon="",ROUND(((brutoloon+(13785/12)*1*8.89)/(1+8.89)),2),ROUND(((brutoloon+(13785/12)*1*8)/(1+8)),2))',
          name: 'uniformloon',
        },
        {
          id: 'd47cff1c-8a35-4624-a249-1aa29e80966e',
          label: 'Loonheffing:',
          expression:
            'IF(payrollTaxCredit="Ja",VLOOKUP(uniformloon, loonhTable, 3,  TRUE),VLOOKUP(uniformloon, loonhTable, 2, TRUE))',
          name: 'loonheffing',
        },
        {
          id: 'a99a9420-71c6-4455-9937-0b825da6a5d9',
          label: 'Toeslag bedrijfskleding:',
          expression: 'receiveSupplementForUsingClothingToeslag',
          name: 'toeslagbedrijfskleding',
        },
        {
          id: '649623cd-7ecb-4c37-a857-58dec70ac995',
          label: 'Eigen bijdrage auto',
          expression: 'numContribution',
          name: 'eigenbijdrageauto',
        },
        {
          id: 'db419156-ae1e-4e30-bc76-73a2c311c86a',
          label: 'Eigen bijdrage auto (niet aftrekbaar)',
          expression: 'numContributionNotDeductible',
          name: 'eigenbijdrageautoniet',
        },
        {
          id: '48a11602-6b82-40d0-8d31-321c9d063419',
          label: 'Nettoloon per maand',
          expression:
            'uniformloon-loonheffing+toeslagbedrijfskleding-eigenbijdrageauto-eigenbijdrageautoniet',
          name: 'nettoloonpermaand',
        },
        {
          id: '4b8245e7-b50e-41e8-a275-2c3f332c416c',
          label: 'Nettoloon per uur',
          expression: 'ROUND(((nettoloonpermaand*12)/52)/contractures,2)',
          name: 'nettoloonperuur',
        },
        {
          id: '4b8245e7-b50e-41e8-a275-2c3f332c416a',
          label: 'Age',
          expression:
            'YEAR(TODAY())-YEAR(dtBirthdate)-IF(MONTH(TODAY())-MONTH(dtBirthdate) >= 0, IF(DAY(TODAY())-DAY(dtBirthdate) >= 0, 0, 1), 1)',
          name: 'ageFormula',
        },
      ],
    },
  ],
  color: '--primary',
  report: { name: '', title: '', disclaimer: '' },
};

export const data: Data = {
  forms: [
    form,
    {
      id: '2',
      name: 'Ik ben werknemer',
      steps: [step1, step2, step3],
      reportGroups: [
        {
          formulas: [
            {
              expression: 'table',
              id: '1',
              label: 'Table value',
              name: 'tableReport',
            },
          ],
          id: '1',
          name: 'tableReport',
        },
      ],
      color: '--secondary',
      isDirty: false,
      report: { name: '', title: '', disclaimer: '' },
    },
    {
      id: '3',
      name: 'Ik ben vakantiewerker',
      steps: [step1, step2, step3],
      reportGroups: [],
      color: '--gray',
      isDirty: false,
      report: { name: '', title: '', disclaimer: '' },
    },
  ],
  tables: [
    {
      id: '491f08bd-06f4-41f8-bbc3-b7c05cc261f1',
      data: [
        ['A.06 Medewerker facilitaire dienst', 2],
        ['A.04 Telefonist(e)/receptionist(e)', 3],
        ['A.01 Medewerker HRM', 6],
        ['A.05 Secretaresse I', 6],
        ['A.05 Secretaresse II', 7],
        ['A.05 Secretaresse III', 8],
        ['A.02 Personeelsfunctionaris', 9],
        ['A.03 Manager HR', 11],
      ],
      header: ['text', 'number'],
      types: ['text', 'number'],
      name: 'FunctiegroepAlgemeenTable',
      label: 'Functiegroep algemeen',
      isDirty: false,
    },
    {
      id: 'ad15b625-1817-4443-8abb-8624f30d4779',
      data: [
        [
          0,
          '10,79',
          '12,4',
          '12,62',
          '12,82',
          '13,11',
          '13,38',
          '13,58',
          '13,93',
          '14,32',
          '14,8',
          '15,41',
        ],
        [
          1,
          '11,53',
          '12,65',
          '12,89',
          '13,13',
          '13,45',
          '13,8',
          '14,06',
          '14,46',
          '14,94',
          '15,52',
          '16,24',
        ],
        [
          2,
          '11,88',
          '12,89',
          '13,17',
          '13,44',
          '13,8',
          '14,23',
          '14,53',
          '15,01',
          '15,55',
          '16,29',
          '17,09',
        ],
        [
          3,
          '12,24',
          '13,14',
          '13,45',
          '13,76',
          '14,17',
          '14,67',
          '15,02',
          '15,54',
          '16,17',
          '17,04',
          '17,92',
        ],
        [
          4,
          '12,59',
          '13,38',
          '13,74',
          '14,08',
          '14,5',
          '15,05',
          '15,5',
          '16,09',
          '16,82',
          '17,75',
          '18,74',
        ],
        [
          5,
          '12,93',
          '13,64',
          '14,02',
          '14,39',
          '14,86',
          '15,47',
          '15,99',
          '16,64',
          '17,43',
          '18,51',
          '19,59',
        ],
        [
          6,
          '13,26',
          '13,87',
          '14,3',
          '14,73',
          '15,22',
          '15,9',
          '16,48',
          '17,18',
          '18,05',
          '19,26',
          '20,42',
        ],
        [
          7,
          '13,63',
          '14,15',
          '14,61',
          '15,02',
          '15,54',
          '16,32',
          '16,97',
          '17,71',
          '18,67',
          '20,01',
          '21,27',
        ],
        [
          8,
          '13,97',
          '14,37',
          '14,87',
          '15,34',
          '15,91',
          '16,72',
          '17,46',
          '18,27',
          '19,29',
          '20,75',
          '22,08',
        ],
        [
          9,
          '14,32',
          '14,66',
          '15,16',
          '15,63',
          '16,27',
          '17,15',
          '17,94',
          '18,8',
          '19,9',
          '21,48',
          '22,92',
        ],
        [
          10,
          '',
          '',
          '',
          '15,97',
          '16,61',
          '17,56',
          '18,42',
          '19,34',
          '20,53',
          '22,23',
          '23,76',
        ],
        [
          11,
          '',
          '',
          '',
          '',
          '16,96',
          '17,98',
          '18,92',
          '19,87',
          '21,17',
          '22,98',
          '24,6',
        ],
        [
          12,
          '',
          '',
          '',
          '',
          '',
          '',
          '19,39',
          '20,43',
          '21,78',
          '23,73',
          '25,43',
        ],
        [13, '', '', '', '', '', '', '', '20,95', '22,4', '24,46', '26,25'],
        [14, '', '', '', '', '', '', '', '', '23,01', '25,2', '27,09'],
        [15, '', '', '', '', '', '', '', '', '', '', '27,94'],
      ],
      header: [
        'number',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
      ],
      types: [
        'number',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
      ],
      name: 'FunctiejarenTabel',
      label: 'Functiejaren',
      isDirty: false,
    },
  ],
};
