import React from 'react';
import { Button, Icon } from '@material-ui/core';

import { MESSAGES } from '../../constants/messages';

type Props = {
  label?: string;
  icon?: string;
  onClick: () => void;
};

export const EditButton: React.FC<Props> = (props) => {
  const { label = MESSAGES.edit, icon = 'settings', onClick } = props;

  return (
    <Button
      onClick={onClick}
      color="secondary"
      variant="contained"
      startIcon={<Icon>{icon}</Icon>}
    >
      {label}
    </Button>
  );
};
