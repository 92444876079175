import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { IS_DEBUG } from '../constants/config';
import { changeDateAndNumberFormat } from '../helpers/helpers';
import { DerivedFields } from './ReportBlock';

type Props = {
  field: DerivedFields[number];
};

const ReportRow: React.FC<Props> = ({ field }) => {
  if (Array.isArray(field.value.result)) {
    if (field.value.result.length === 0) {
      return null;
    }

    return (
      <>
        <TableRow>
          <TableCell component="th" scope="row" colSpan={2}>
            {field.formula.label}
          </TableCell>
        </TableRow>
        {field.value.result.map(([title, value], rowIndex) => (
          <TableRow key={rowIndex}>
            <TableCell component="th" scope="row">
              {title}
            </TableCell>
            <TableCell align="right">{value}</TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  if (!field.value.result) {
    return null;
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {field.formula.label}
      </TableCell>
      {IS_DEBUG && (
        <TableCell>
          <Box>{field.formula.name}</Box>
          <Box>{field.formula.expression}</Box>
        </TableCell>
      )}
      <TableCell align="right">
        <Typography
          variant="body2"
          color={field.value.result === null ? 'error' : 'initial'}
        >
          {changeDateAndNumberFormat(field.value.result)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ReportRow;
