import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { Parser } from 'hot-formula-parser';
import { calculateSelectItemValue } from '../helpers/helpers';

type Props = {
  name: string;
  label: string;
  values: string[];
  value: string | number | undefined;
  placeholder?: string;
  isValid: boolean;
  validationMessage: string | null;
  onChange: (name: string, value: string | number | undefined) => void;
  parser: Parser;
  additionalValuesFormula?: string;
};

const SelectInput: React.FC<Props> = (props) => {
  const {
    name,
    label,
    value,
    values,
    placeholder,
    isValid,
    validationMessage,
    onChange,
    parser,
    additionalValuesFormula,
  } = props;

  const handleValueChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    e.preventDefault();

    onChange(name, `${e.target.value}`);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}LabelId`} error={!isValid}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}LabelId`}
        id={`${name}Id`}
        name={name}
        value={value}
        onChange={handleValueChange}
        placeholder={placeholder}
        error={!isValid}
      >
        {values.map((item) => (
          <MenuItem key={item} value={item}>
            {additionalValuesFormula
              ? calculateSelectItemValue(parser, additionalValuesFormula, item)
              : item}
          </MenuItem>
        ))}
      </Select>
      {validationMessage && (
        <FormHelperText error={!isValid}>{validationMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectInput;
