import { InputLabelProps, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Parser } from 'hot-formula-parser';
import React from 'react';
import { validate } from '../helpers/validation';
import { CustomField } from '../models/field';
import DatePickerInput from './DatePickerInput';
import MultiSelectField from './MultiSelectField';
import NumberInput from './NumberInput';
import SelectInput from './SelectInput';
import { changeDateAndNumberFormat } from '../helpers/helpers';

type Props = {
  field: CustomField;
  value: string | number | undefined;
  parser: Parser;
  isValidationActive: boolean;
  onChange: (name: string, value: string | number | undefined) => void;
};

const FieldRenderer: React.FC<Props> = ({
  field,
  value,
  parser,
  isValidationActive,
  onChange,
}) => {
  const inputLabelProps = {} as Partial<InputLabelProps>;

  if (field.placeholder) {
    inputLabelProps.shrink = true;
  }

  // pass the is validation active boolean
  const { isValid, validationMessage } = validate(
    parser,
    field.validationFormula,
    isValidationActive
  );

  if (field.type === 'text') {
    return (
      <TextField
        name={field.name}
        label={field.label}
        fullWidth
        type={field.type}
        value={value ?? ''}
        placeholder={field.placeholder}
        InputLabelProps={inputLabelProps}
        onChange={({ target }) => onChange(target.name, target.value)}
        error={!isValid}
        helperText={validationMessage}
      />
    );
  }

  if (field.type === 'number') {
    return (
      <NumberInput
        name={field.name}
        label={field.label}
        minValue={field.minValue}
        maxValue={field.maxValue}
        value={value}
        placeholder={field.placeholder}
        isValid={isValid}
        validationMessage={validationMessage}
        onChange={onChange}
      />
    );
  }

  if (field.type === 'date') {
    return (
      <DatePickerInput
        name={field.name}
        label={field.label}
        value={value}
        placeholder={field.placeholder}
        isValid={isValid}
        validationMessage={validationMessage}
        onChange={onChange}
      />
    );
  }

  if (field.type === 'formula') {
    return (
      <TextField
        name={field.name}
        label={field.label}
        fullWidth
        value={changeDateAndNumberFormat(value ?? '')}
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={inputLabelProps}
        placeholder={field.placeholder}
        disabled
        error={!isValid}
        helperText={validationMessage}
      />
    );
  }

  if (field.type === 'select') {
    return (
      <SelectInput
        name={field.name}
        label={field.label}
        values={field.values}
        value={value}
        placeholder={field.placeholder}
        isValid={isValid}
        validationMessage={validationMessage}
        onChange={onChange}
        parser={parser}
        additionalValuesFormula={field.additionalValuesFormula}
      />
    );
  }

  if (field.type === 'groupedSelect') {
    const options = field.groups.flatMap((group) =>
      group.values.map((value) => ({ group: group.name, value }))
    );

    return (
      <Autocomplete
        options={options}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.value}
        fullWidth
        value={options.find((item) => item.value === value) ?? null}
        onChange={(event: any, newValue: any) =>
          onChange(field.name, newValue?.value)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={field.label}
            name={field.name}
            placeholder={field.placeholder}
            InputLabelProps={inputLabelProps}
            value={value ?? ''}
            error={!isValid}
            helperText={validationMessage}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.value, inputValue);
          const parts = parse(option.value, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    );
  }

  if (field.type === 'multiSelect') {
    // the typing needs to be made stricter here
    return (
      <MultiSelectField
        name={field.name}
        label={field.label}
        values={field.values}
        value={(value ?? []) as [string, number][]}
        placeholder={field.placeholder}
        onChange={
          (onChange as any) as (name: string, value: [string, number][]) => void
        }
      />
    );
  }

  return null;
};

export default FieldRenderer;
