import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminTables from '../components/admin/sections/AdminTables';
import AdminForms from '../components/admin/sections/AdminForms';

const useStyles = makeStyles({
  grid: {
    background: 'white',
    marginBottom: '5rem',
    boxShadow: '0 25px 50px -12px rgba(0,0,0,.25)',
  },
});

type Props = {
  isReadOnly: boolean;
  token: string;
};

export const IsReadOnlyContext = React.createContext(false);
export const TokenContext = React.createContext('');

const Admin: React.FC<Props> = ({ isReadOnly, token }) => {
  const classes = useStyles();

  return (
    <IsReadOnlyContext.Provider value={isReadOnly}>
      <TokenContext.Provider value={token}>
        <Grid container justify="center">
          <Grid item lg={6} md={8} xs={12} className={classes.grid}>
            <Switch>
              <Route path="/admin/forms">
                <AdminForms />
              </Route>
              <Route path="/admin/tables">
                <AdminTables />
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </TokenContext.Provider>
    </IsReadOnlyContext.Provider>
  );
};

export default Admin;
