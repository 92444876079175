import { ENDPOINTS } from '../constants/endpoints';
import axios from 'axios';
import { logError } from '../helpers/helpers';
import { EMAIL } from '../constants/email';

export const sendEmail = async (emailAddress: string, fileContent: string) => {
  const data = { email: emailAddress, fileContent, body: EMAIL };

  try {
    await axios.post(ENDPOINTS.mailSend, data);
  } catch (error) {
    throw logError(error);
  }
};
