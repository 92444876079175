import { AppBar, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MESSAGES } from '../constants/messages';

const Navigation = () => {
  const history = useHistory();
  const location = useLocation();

  const links = [
    { label: MESSAGES.linkForms, value: '/' },
    { label: MESSAGES.linkAdminForms, value: '/admin/forms' },
    { label: MESSAGES.linkAdminTables, value: '/admin/tables' },
  ];

  const selected = links.find((link) => link.value === location.pathname);

  return (
    <nav>
      <AppBar position="static">
        <Tabs
          value={selected?.value ?? null}
          onChange={(event, value) => history.push(value)}
        >
          {links.map((link) => (
            <Tab key={link.value} label={link.label} value={link.value} />
          ))}
          <Tab style={{ display: 'none' }} value={null}></Tab>
        </Tabs>
      </AppBar>
    </nav>
  );
};

export default Navigation;
