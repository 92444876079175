import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';

type Type = 'success' | 'info' | 'error' | 'warning';

export const useAlerts = (messages: {
  [type: string]: string;
}): [React.FC, (type: Type, isOpen: boolean) => void] => {
  const [alerts, setAlerts] = useState({
    info: false,
    success: false,
    warning: false,
    error: false,
  });

  const setAlert = (type: Type, isOpen: boolean) => {
    setAlerts({ ...alerts, [type]: isOpen });
  };

  const Alerts: React.FC = () => {
    return (
      <>
        {Object.entries(alerts).map(([type, isOpen], index) => (
          <Snackbar
            key={index}
            open={alerts[type as Type]}
            autoHideDuration={6000}
            onClose={() => setAlert(type as Type, false)}
          >
            <Alert
              onClose={() => setAlert(type as Type, false)}
              severity={type as Type}
            >
              {messages[type]}
            </Alert>
          </Snackbar>
        ))}
      </>
    );
  };

  return [Alerts, setAlert];
};
