import MomentUtils from '@date-io/moment';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Navigation from './components/Navigation';
import { palettes } from './constants/palettes';
import Admin from './pages/Admin';
import Home from './pages/Home';
import User from './pages/User';
import { nl } from 'date-fns/locale';

const root = document.querySelector('[data-react="salary-calculator"]');

const { organization = 'ssfb' } = (root as HTMLElement)?.dataset ?? {};

root?.classList.add(organization);

type Organization = 'ssfb' | 'nbov' | 'fnv';

const options = {
  palette: palettes[organization as Organization],
};

const theme = createMuiTheme(options, nl);

type Props = {
  token?: string;
  organization?: string;
  readonly?: string;
};

const App: React.FC<Props> = ({ token, readonly }) => {
  const isReadOnly = !!readonly;
  const isAdmin = !!token || isReadOnly;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            {isAdmin && <Navigation />}

            <Switch>
              {isAdmin && (
                <Route path="/admin">
                  <Admin isReadOnly={isReadOnly} token={token ?? ''} />
                </Route>
              )}
              <Route path="/form/:id/:stepId">
                <User />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
