import {
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { MESSAGES } from '../constants/messages';

type Value = [string, number][];

type Props = {
  name: string;
  label: string;
  values: string[];
  value: Value;
  placeholder?: string;
  onChange: (name: string, value: Value) => void;
};

const MultiSelectField: React.FC<Props> = (props) => {
  const { name, label, value, values, placeholder, onChange } = props;

  const handleValueChange = (
    inputValue: string,
    index: number,
    isCategory: boolean
  ) => {
    const parsedValue = isCategory ? inputValue : parseFloat(inputValue);

    const newValue = value.map((item, i) => {
      const newItem = [...item];

      if (index !== i) {
        return newItem;
      }

      newItem[isCategory ? 0 : 1] = parsedValue;

      return newItem;
    });

    onChange(name, newValue as Value);
  };

  const removeIndex = (index: number) => {
    const newValue = [...value];

    newValue.splice(index, 1);

    onChange(name, newValue);
  };

  const addItem = () => {
    const newValue = [...value, []];

    onChange(name, newValue as Value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={11} xs={10}>
        <InputLabel>{label}</InputLabel>
      </Grid>
      <Grid item sm={1} xs={2}>
        <IconButton onClick={addItem}>
          <Icon>add</Icon>
        </IconButton>
      </Grid>
      {value.map((field, index) => (
        <React.Fragment key={index}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id={`${name}LabelId`}>{MESSAGES.category}</InputLabel>
              <Select
                labelId={`${name}LabelId`}
                id={`${name}Id`}
                name={name}
                label={MESSAGES.category}
                value={field[0]}
                onChange={({ target }) =>
                  handleValueChange(target.value as string, index, true)
                }
                placeholder={placeholder}
              >
                {values.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={5} xs={10}>
            <TextField
              name={name}
              label={MESSAGES.workHoursLabel}
              id={`${name}Id`}
              type="number"
              value={field[1]}
              onChange={({ target }) =>
                handleValueChange(target.value, index, false)
              }
              placeholder={placeholder}
              fullWidth
            />
          </Grid>
          <Grid item sm={1} xs={2}>
            <IconButton onClick={() => removeIndex(index)}>
              <Icon>delete</Icon>
            </IconButton>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default MultiSelectField;
