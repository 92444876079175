import { ENDPOINTS } from '../constants/endpoints';
import Form from '../models/form';
import axios from 'axios';
import { logError } from '../helpers/helpers';
import { data as testData } from '../constants/data';

interface FormsData {
  records: { id: string; name: string; json: string }[];
}

export const getAllForms = async () => {
  try {
    const { data } = await axios.get<FormsData>(ENDPOINTS.formsGetAll);
    const forms = data.records.map((item) => JSON.parse(item.json)) as Form[];

    return forms;
  } catch (error) {
    if (process.env.NODE_ENV === 'production') {
      throw logError(error);
    } else {
      return testData.forms;
    }
  }
};

export const getFormById = async (id: string) => {
  try {
    const response = await axios.get<Form>(ENDPOINTS.formsGet, {
      params: { id },
    });

    return response.data;
  } catch (error) {
    throw logError(error);
  }
};

export const deleteForm = async (id: string, token: string) => {
  try {
    await axios.delete(ENDPOINTS.formsDelete, {
      data: { id },
      params: { token },
    });
  } catch (error) {
    throw logError(error);
  }
};

export const createForm = async (form: Form, token: string) => {
  const data = { id: form.id, name: form.name, json: JSON.stringify(form) };

  try {
    await axios.post(ENDPOINTS.formsCreate, data, { params: { token } });
  } catch (error) {
    throw logError(error);
  }
};

export const updateForm = async (form: Form, token: string) => {
  const data = { id: form.id, name: form.name, json: JSON.stringify(form) };

  try {
    await axios.post(ENDPOINTS.formsUpdate, data, { params: { token } });
  } catch (error) {
    throw logError(error);
  }
};
