import { Dispatch, useEffect, useReducer, useCallback } from 'react';
import { tableDispatchMiddleware } from '../middleware/tablesMiddleware';
import { Action, State, tablesReducer } from '../reducers/tablesReducer';

export const useTables = (): [State, Dispatch<Action>] => {
  const [state, dispatch] = useReducer(tablesReducer, {
    tables: [],
    isLoading: false,
  });

  const dispatchWithMiddleware = useCallback(
    tableDispatchMiddleware(dispatch),
    [dispatch]
  );

  useEffect(() => {
    dispatchWithMiddleware({ type: 'REQUEST_TABLES' });
  }, [dispatchWithMiddleware]);

  return [state, dispatchWithMiddleware];
};
