import { v4 } from 'uuid';
import { Table } from '../models/table';
import { MESSAGES } from '../constants/messages';

export type Action =
  | { type: 'REQUEST_TABLES' }
  | { type: 'RECEIVE_TABLES'; payload: { tables: Table[] } }
  | { type: 'SAVE_TABLES'; payload: { tables: Table[]; token: string } }
  | { type: 'REMOVE_TABLE'; payload: { tableId: string; token: string } }
  | { type: 'ADD_TABLE'; payload: { table: Table; token: string } }
  | { type: 'UPDATE_TABLE'; payload: { table: Partial<Table> } }
  | {
      type: 'UPDATE_TABLE_DATA_VALUE';
      payload: {
        tableId: string;
        rowIndex: number;
        cellIndex: number;
        value: string | number;
      };
    };

export type State = { tables: Table[]; isLoading: boolean };

export const tablesReducer = (state: State, action: Action): State => {
  if (action.type === 'REQUEST_TABLES') {
    return { ...state, isLoading: true };
  }

  if (action.type === 'RECEIVE_TABLES') {
    return { ...state, isLoading: false, tables: [...action.payload.tables] };
  }

  if (action.type === 'SAVE_TABLES') {
    return {
      ...state,
      tables: state.tables.map((item) => ({ ...item, isDirty: false })),
    };
  }

  if (action.type === 'ADD_TABLE') {
    return {
      ...state,
      tables: [
        ...state.tables,
        {
          id: v4(),
          data: [],
          header: [],
          types: [],
          name: MESSAGES.table,
          label: MESSAGES.newTable,
          isDirty: false,
          ...(action.payload?.table || {}),
        },
      ],
    };
  }

  if (action.type === 'REMOVE_TABLE') {
    return {
      ...state,
      tables: state.tables.filter(
        (table) => table.id !== action.payload.tableId
      ),
    };
  }

  if (action.type === 'UPDATE_TABLE') {
    return {
      ...state,
      tables: state.tables.map((table) =>
        table.id === action.payload.table.id
          ? {
              ...table,
              ...action.payload.table,
              isDirty: true,
            }
          : table
      ),
    };
  }

  if (action.type === 'UPDATE_TABLE_DATA_VALUE') {
    return {
      ...state,
      tables: state.tables.map((table) =>
        table.id === action.payload.tableId
          ? {
              ...table,
              isDirty: true,
              data: table.data.map((row, rowIndex) =>
                row.map((cell, cellIndex) =>
                  rowIndex === action.payload.rowIndex &&
                  cellIndex === action.payload.cellIndex
                    ? action.payload.value
                    : cell
                )
              ),
            }
          : table
      ),
    };
  }

  return state;
};
