import { Dispatch } from 'react';
import { Action } from '../reducers/tablesReducer';
import {
  getAllTables,
  createTable,
  deleteTable,
  updateTable,
} from '../api/tablesAPI';

export const tableDispatchMiddleware = (dispatch: Dispatch<Action>) => {
  return async (action: Action) => {
    if (action.type === 'REQUEST_TABLES') {
      dispatch(action);

      const tables = await getAllTables();

      return dispatch({ type: 'RECEIVE_TABLES', payload: { tables } });
    }

    if (action.type === 'ADD_TABLE') {
      await createTable(action.payload.table, action.payload.token);
    }

    if (action.type === 'REMOVE_TABLE') {
      await deleteTable(action.payload.tableId, action.payload.token);
    }

    if (action.type === 'SAVE_TABLES') {
      const promises = action.payload.tables
        .filter((table) => table.isDirty)
        .map((table) =>
          updateTable({ ...table, isDirty: false }, action.payload.token)
        );

      await Promise.all(promises);
    }

    return dispatch(action);
  };
};
