import React, { Dispatch, useContext } from 'react';
import { GroupedSelectField as Field } from '../../models/field';
import { isEmpty } from '../../helpers/helpers';
import { Grid, TextField, Chip, IconButton, Icon } from '@material-ui/core';
import { MESSAGES } from '../../constants/messages';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AddButton } from './AddButton';
import { v4 } from 'uuid';
import { Action } from '../../reducers/formsReducer';
import { IsReadOnlyContext } from '../../pages/Admin';

type Props = {
  field: Field;
  formId: string;
  stepId: string;
  dispatch: Dispatch<Action>;
};

const GroupedSelectField: React.FC<Props> = ({
  field,
  formId,
  stepId,
  dispatch,
}) => {
  const isReadOnly = useContext(IsReadOnlyContext);

  const handleChange = (
    name: string,
    value: { id: string; name: string; values: string[] }[]
  ) => {
    const newField = { ...field, [name]: value };

    dispatch({
      type: 'UPDATE_FIELD',
      payload: { formId, stepId, field: newField },
    });
  };

  return (
    <Grid container spacing={3}>
      {!isEmpty(field.groups) &&
        field.groups.map((group) => (
          <React.Fragment key={group.id}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                value={group.name}
                label={MESSAGES.group}
                onChange={(event) => {
                  const groups = field.groups.map((item) =>
                    item.id === group.id
                      ? { ...item, name: event.target.value }
                      : item
                  );
                  handleChange('groups', groups);
                }}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                multiple
                options={[] as string[]}
                freeSolo
                disableClearable
                value={group.values}
                onChange={(event, values) => {
                  const groups = field.groups.map((item) =>
                    item.id === group.id ? { ...item, values } : item
                  );
                  handleChange('groups', groups);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({
                        index,
                      })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={MESSAGES.values}
                  />
                )}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={1}>
              {!isReadOnly && (
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    handleChange(
                      'groups',
                      field.groups.filter((item) => item.id !== group.id)
                    )
                  }
                >
                  <Icon>delete</Icon>
                </IconButton>
              )}
            </Grid>
          </React.Fragment>
        ))}
      <Grid item xs={12}>
        {!isReadOnly && (
          <AddButton
            onClick={() =>
              handleChange('groups', [
                ...(field.groups || []),
                {
                  id: v4(),
                  name: MESSAGES.newGroup,
                  values: [],
                },
              ])
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default GroupedSelectField;
