import { IS_DEBUG } from './config';

export const MESSAGES = {
  linkForms: 'Loonberekeningsmodule',
  linkAdminForms: 'Admin formulieren',
  linkAdminTables: 'Admin tabellen',
  add: 'Toevoegen',
  edit: 'Bewerken',
  delete: 'Verwijderen',
  save: 'Opslaan',
  functions: 'Functies',
  noOptions: 'Geen opties',
  label: 'Label',
  type: 'Type',
  info: 'Knopinfo voor informatie',
  placeholder: 'Tijdelijke aanduiding',
  defaultValue: 'Standaardwaarde',
  values: 'Waarden',
  group: 'Groep',
  newGroup: 'Nieuw groep',
  forms: 'Formulieren',
  newForm: 'Nieuw formulier',
  noForms: 'Geen formulieren',
  steps: 'Stappen',
  newStep: 'Nieuwe stap',
  noSteps: 'Geen stappen',
  newField: 'Nieuw veld',
  field: 'Veld',
  fields: 'Velden',
  noFields: 'Geen velden',
  formula: 'Formule',
  formulas: 'Formules',
  newFormula: 'Nieuw formule',
  noFormulas: 'Geen formules',
  formulaShowField: 'Moet het veld worden weergegeven in het formulier?',
  reportGroup: 'Rapportgroep',
  reportGroups: 'Rapportgroepen',
  newReportGroup: 'Nieuwe rapportgroep',
  noReportGroups: 'Geen rapportgroepen',
  name: 'Naam',
  previous: 'Vorige',
  next: 'Volgende',
  report: 'Rapport',
  results: 'Resultaten',
  result: 'Resultaat',
  table: 'Tabel',
  newTable: 'Nieuwe tabel',
  tables: 'Tabellen',
  noTables: 'Geen tabellen',
  import: 'Tabelgegevens importeren',
  ok: 'OK',
  cancel: 'Annuleren',
  clear: 'Ontruimen',
  invalid: 'Ongeldig',
  today: 'Vandaag',
  exportPdf: 'Exporteren naar PDF',
  reportName: 'Rapport',
  deletionConfirmation: 'Weet je zeker dat je wilt verwijderen?',
  minValue: 'Min waarde',
  maxValue: 'Max waarde',
  title: 'Titel',
  description: 'Omschrijving',
  disclaimer: 'Disclaimer',
  numberError: 'Het nummer moet tussen {0} en {1} zijn.',
  sendEmail: 'Stuur de e-mail',
  emailToSent: 'e-mailadres',
  noValue: 'Waarde ontbreekt',
  calculationDate: 'Datum proforma loonberekening:',
  preview: 'Voorbeeld',
  category: 'Categorie',
  workHoursLabel: 'Aantal toeslaguren',
  validationFormula: 'Validatie formule',
  validationError: 'Validation Error',
  additionalValuesFormula:
    'Een formule voor het berekenen van de uurlonen voor de selectielijst',
};

export const TYPE_MESSAGES = {
  text: 'Tekst',
  number: 'Aantal',
  date: 'Datum',
  select: 'Lijst',
  groupedSelect: 'Gegroepeerde lijst',
  formula: 'Formule',
  multiSelect: 'Multi select',
};

export const ALERT_MESSAGES = {
  exportSucces: 'PDF is succesvol geëxporteerd.',
  exportInfo: 'PDF export wordt opgestart.',
  exportError: 'PDF export is mislukt.',
  emailSucces: 'PDF is succesvol gegenereerd en verzonden.',
  emailInfo: 'PDF wordt gegenereerd en verzonden.',
  emailError:
    'Het genereren van PDF en/of het versturen van e-mail is mislukt.',
};

// english messages codes shown for development
if (IS_DEBUG) {
  // @ts-ignore
  Object.keys(MESSAGES).forEach((key) => (MESSAGES[key] = key));
  // @ts-ignore
  Object.keys(TYPE_MESSAGES).forEach((key) => (TYPE_MESSAGES[key] = key));
  // @ts-ignore
  Object.keys(ALERT_MESSAGES).forEach((key) => (ALERT_MESSAGES[key] = key));
}
