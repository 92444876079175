import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import React, { Dispatch, useContext, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import {
  DEFAULT_DECIMAL_SEPARATOR,
  DEFAULT_THOUSAND_SEPARATOR,
} from '../../constants/config';
import { MESSAGES } from '../../constants/messages';
import { changeDateAndNumberFormat, isMobile } from '../../helpers/helpers';
import { IsReadOnlyContext, TokenContext } from '../../pages/Admin';
import { Action } from '../../reducers/tablesReducer';
import { NumberFormatInput } from '../common/NumberFormatInput';
import { DeleteButton } from './DeleteButton';
import { EditButton } from './EditButton';
import { PreviewButton } from './PreviewButton';
import { useCardClasses } from './Styled';
import { Title } from './Title';

type Props = {
  header: string[];
  types: ('text' | 'number')[];
  data: (string | number | null)[][];
  tableId: string;
  name: string;
  label: string;
  dispatch: Dispatch<Action>;
};

const TableBlock: React.FC<Props> = (props) => {
  const { header, types, data, tableId, name, label, dispatch } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [editing, setEditing] = useState<{
    rowIndex?: number;
    cellIndex?: number;
  }>({});
  const [headerEditingIndex, setHeaderEditingIndex] = useState<number>();
  const classes = useCardClasses();
  const token = useContext(TokenContext);
  const isReadOnly = useContext(IsReadOnlyContext);

  const openSteps = () => setIsTableOpen(true);
  const closeSteps = () => setIsTableOpen(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const shownTable = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const removeTable = () =>
    dispatch({ type: 'REMOVE_TABLE', payload: { tableId, token } });

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({
      type: 'UPDATE_TABLE',
      payload: { table: { id: tableId, [target.name]: target.value } },
    });

  const onHeaderInputChange = (value: string, index: number) =>
    dispatch({
      type: 'UPDATE_TABLE',
      payload: {
        table: {
          id: tableId,
          header: header.map((item, i) => (i === index ? value : item)),
        },
      },
    });

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={MESSAGES.name}
                value={name}
                name="name"
                onChange={onChange}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={MESSAGES.label}
                value={label}
                name="label"
                onChange={onChange}
                disabled={isReadOnly}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          {isReadOnly ? (
            <PreviewButton onClick={openSteps} />
          ) : (
            <>
              <EditButton onClick={openSteps} />
              <DeleteButton onClick={removeTable} />
            </>
          )}
        </CardActions>
      </Card>

      <SwipeableDrawer
        anchor="right"
        open={isTableOpen}
        onClose={closeSteps}
        onOpen={openSteps}
      >
        <Box p={2} style={{ width: !isMobile() ? '80vw' : 'auto' }}>
          <Title onClick={closeSteps} title={`${name} - ${MESSAGES.table}`} />
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="table">
              {header && (
                <TableHead>
                  <TableRow>
                    {header.map((item, index) => (
                      <TableCell
                        key={index}
                        onClick={() =>
                          !isReadOnly && setHeaderEditingIndex(index)
                        }
                      >
                        {headerEditingIndex === index ? (
                          <TextField
                            style={{ minWidth: 200 }}
                            variant="outlined"
                            fullWidth
                            value={item}
                            onChange={({ target }) =>
                              onHeaderInputChange(target.value, index)
                            }
                            disabled={isReadOnly}
                          />
                        ) : (
                          item
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {shownTable.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <CustomCell
                        isEditing={
                          rowIndex === editing.rowIndex &&
                          cellIndex === editing.cellIndex &&
                          !isReadOnly
                        }
                        type={types[cellIndex]}
                        key={cellIndex}
                        value={cell}
                        onClick={() =>
                          !isReadOnly && setEditing({ rowIndex, cellIndex })
                        }
                        rowIndex={rowIndex}
                        cellIndex={cellIndex}
                        tableId={tableId}
                        dispatch={dispatch}
                      />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

type CustomCellProps = {
  value: string | number | null;
  type: 'text' | 'number';
  isEditing: boolean;
  tableId: string;
  rowIndex: number;
  cellIndex: number;
  onClick: () => void;
  dispatch: Dispatch<Action>;
};

const CustomCell: React.FC<CustomCellProps> = (props) => {
  const {
    value,
    isEditing,
    rowIndex,
    cellIndex,
    tableId,
    type,
    onClick,
    dispatch,
  } = props;

  const isReadOnly = useContext(IsReadOnlyContext);

  const handleChange = (value: string | number = '') => {
    dispatch({
      type: 'UPDATE_TABLE_DATA_VALUE',
      payload: {
        tableId,
        rowIndex,
        cellIndex,
        value,
      },
    });
  };

  const handleNumberChange = (value: NumberFormatValues) =>
    handleChange(value.floatValue);

  return (
    <TableCell onClick={onClick}>
      {isEditing ? (
        type === 'text' ? (
          <TextField
            variant="outlined"
            value={value}
            autoFocus
            type={type}
            onChange={({ target }) => handleChange(target.value)}
            disabled={isReadOnly}
          />
        ) : (
          <NumberFormatInput
            customInput={TextField}
            thousandSeparator={DEFAULT_THOUSAND_SEPARATOR}
            decimalSeparator={DEFAULT_DECIMAL_SEPARATOR}
            variant="outlined"
            value={value ?? ''}
            autoFocus
            disabled={isReadOnly}
            onValueChange={handleNumberChange}
          />
        )
      ) : (
        changeDateAndNumberFormat(value)
      )}
    </TableCell>
  );
};

export default React.memo(TableBlock);
