import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Icon,
  TextField,
} from '@material-ui/core';
import React, { Dispatch, useContext } from 'react';
import { MESSAGES } from '../../constants/messages';
import { Formula } from '../../models/formula';
import { Action } from '../../reducers/formsReducer';
import { DeleteButton } from './DeleteButton';
import { OptionsContext } from './FormBlock';
import FormulaAutocomplete from './FormulaAutocomplete';
import { useCardClasses } from './Styled';
import { IsReadOnlyContext } from '../../pages/Admin';

type Props = {
  formId: string;
  groupId: string;
  formula: Formula;
  dispatch: Dispatch<Action>;
};

const FormulaBlock: React.FC<Props> = ({
  formId,
  groupId,
  formula,
  dispatch,
}) => {
  const classes = useCardClasses();

  const removeFormula = () =>
    dispatch({
      type: 'REMOVE_FORMULA',
      payload: { formId, groupId, formulaId: formula.id },
    });

  const updateFormula = (name: string, value: string) =>
    dispatch({
      type: 'UPDATE_FORMULA',
      payload: {
        formId,
        groupId,
        formula: { ...formula, [name]: value },
      },
    });

  const options = useContext(OptionsContext);
  const isReadOnly = useContext(IsReadOnlyContext);

  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    updateFormula(target.name, target.value);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              label={MESSAGES.label}
              fullWidth
              variant="outlined"
              name="label"
              value={formula.label}
              onChange={handleInputChange}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={MESSAGES.name}
              fullWidth
              variant="outlined"
              name="name"
              value={formula.name}
              onChange={handleInputChange}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <FormulaAutocomplete
              options={options}
              onChange={(value) => updateFormula('expression', value)}
              value={formula.expression}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        {!isReadOnly && (
          <>
            <DeleteButton onClick={removeFormula} />
            <Box style={{ marginLeft: 'auto' }}>
              <Icon className="drag-handle">drag_handle</Icon>
            </Box>
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default React.memo(FormulaBlock);
