import { MESSAGES } from './messages';

export const FUNCTIONS = [
  { type: MESSAGES.functions, title: 'ABS' },
  { type: MESSAGES.functions, title: 'ADD' },
  { type: MESSAGES.functions, title: 'AGGREGATE' },
  { type: MESSAGES.functions, title: 'AND' },
  { type: MESSAGES.functions, title: 'AVERAGE' },
  { type: MESSAGES.functions, title: 'AVERAGEIF' },
  { type: MESSAGES.functions, title: 'CEILING' },
  { type: MESSAGES.functions, title: 'COUNT' },
  { type: MESSAGES.functions, title: 'COUNTIF' },
  { type: MESSAGES.functions, title: 'DATE' },
  { type: MESSAGES.functions, title: 'DATEVALUE' },
  { type: MESSAGES.functions, title: 'DAY' },
  { type: MESSAGES.functions, title: 'DAYS' },
  { type: MESSAGES.functions, title: 'DAYS360' },
  { type: MESSAGES.functions, title: 'FLOOR' },
  { type: MESSAGES.functions, title: 'HOUR' },
  { type: MESSAGES.functions, title: 'IF' },
  { type: MESSAGES.functions, title: 'MATCH' },
  { type: MESSAGES.functions, title: 'MAX' },
  { type: MESSAGES.functions, title: 'MEDIAN' },
  { type: MESSAGES.functions, title: 'MID' },
  { type: MESSAGES.functions, title: 'MIN' },
  { type: MESSAGES.functions, title: 'MINUS' },
  { type: MESSAGES.functions, title: 'MONTH' },
  { type: MESSAGES.functions, title: 'MULTIPLY' },
  { type: MESSAGES.functions, title: 'NOT' },
  { type: MESSAGES.functions, title: 'OR' },
  { type: MESSAGES.functions, title: 'POW' },
  { type: MESSAGES.functions, title: 'POWER' },
  { type: MESSAGES.functions, title: 'ROUND' },
  { type: MESSAGES.functions, title: 'ROUNDDOWN' },
  { type: MESSAGES.functions, title: 'ROUNDUP' },
  { type: MESSAGES.functions, title: 'SUM' },
  { type: MESSAGES.functions, title: 'SUMIF' },
  { type: MESSAGES.functions, title: 'TIME' },
  { type: MESSAGES.functions, title: 'TIMEVALUE' },
  { type: MESSAGES.functions, title: 'TODAY' },
  { type: MESSAGES.functions, title: 'WEEKDAY' },
  { type: MESSAGES.functions, title: 'WEEKNUM' },
  { type: MESSAGES.functions, title: 'WORKDAY' },
  { type: MESSAGES.functions, title: 'YEAR' },
  { type: MESSAGES.functions, title: 'YEARFRAC' },
  // Custom functions
  { type: MESSAGES.functions, title: 'VLOOKUP' },
  { type: MESSAGES.functions, title: 'HLOOKUP' },
  { type: MESSAGES.functions, title: 'INDEX' },
  { type: MESSAGES.functions, title: 'FORMAT' },
];
