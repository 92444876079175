import Form from '../models/form';
import { CustomField } from '../models/field';
import { Parser } from 'hot-formula-parser';
import moment from 'moment';

export const isEmpty = (item: any[]) => {
  return !item || item.length === 0;
};

export const isMobile = () => {
  return window && window.innerWidth <= 700;
};

export const isNumber = (value: string | number) => {
  return value != null && value !== '' && !isNaN(Number(value.toString()));
};

export const getFieldByName = (
  form: Form,
  fieldName: string
): CustomField | undefined => {
  var foundField = undefined;
  form.steps.forEach((step) => {
    step.fields.forEach((field) => {
      if (field.name === fieldName) {
        foundField = field;
      }
    });
  });

  return foundField;
};

export const calculateSelectItemValue = (
  parser: Parser,
  formula: string,
  value: string
): string => {
  const parsedFormula = formula.split('{value}').join(value);
  const parsed = parser.parse(parsedFormula);
  const parsedValue = parsed.result ?? value;

  return parsedValue;
};

export const normalize = (value: string) => value.trim().toLowerCase();

export const logError = (error: any) => {
  console.error('API', error);
  return error;
};

type Table = (string | number)[][];

export const findNearestValue = (
  cellRange: Table,
  rowIndex: number,
  columnIndex: number
) => {
  // going through rows substracting 1 from row index (up)
  for (let row = rowIndex; row >= 0; row--) {
    const value = cellRange[row][columnIndex];

    if (value) {
      return value.toString();
    }
  }

  return '';
};

export const changeDateAndNumberFormat = (value: string | number | null) => {
  if (!value) {
    return value;
  }

  if (moment(value, 'YYYY-MM-DD', true).isValid()) {
    return moment(value).format('DD-MM-YYYY');
  }

  if (isNumber(value)) {
    return new Intl.NumberFormat('NL').format(value as number);
  }

  return value;
};

export const scrollToElement = (element?: HTMLElement) => {
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};
