import { Box, Tooltip, Icon, IconButton, makeStyles } from '@material-ui/core';
import { Parser } from 'hot-formula-parser';
import React, { useEffect } from 'react';
import { CustomField } from '../models/field';
import FieldRenderer from './FieldRenderer';
import { FormState } from './FormContainer';
import { IS_DEBUG } from '../constants/config';

type Props = {
  field: CustomField;
  formState: FormState;
  parser: Parser;
  isValidationActive: boolean;
  handleFieldChange: (name: string, value: string | number | undefined) => void;
};

const useClasses = makeStyles({
  tooltip: {
    whiteSpace: 'pre-wrap',
  },
});

const FieldContainer: React.FC<Props> = ({
  field,
  formState,
  parser,
  isValidationActive,
  handleFieldChange,
}) => {
  const shouldRender = needToRender(parser, field);

  const classes = useClasses();

  useEffect(() => {
    // this seems to get called more often than it should,
    // need to look into if this resets some values
    // that shouldn't be reset
    if (shouldRender) {
      if (field.type === 'multiSelect') {
        return;
      }

      handleFieldChange(field.name, field.defaultValue);
    } else {
      handleFieldChange(field.name, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRender]);

  const value =
    field.type === 'formula'
      ? parser.parse(field.expression).result
      : formState[field.name];

  if (field.type === 'formula') {
    parser.setVariable(field.name, value);
  }

  return (
    needToRender(parser, field) && (
      <>
        <Box key={field.name} p={2} display="flex" alignItems="center">
          <FieldRenderer
            field={field}
            value={value}
            parser={parser}
            isValidationActive={isValidationActive}
            onChange={handleFieldChange}
          />
          {field.info && (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: field.info }} />}
              arrow
              interactive
              classes={classes}
              enterTouchDelay={0}
            >
              <Box ml={1}>
                <IconButton>
                  <Icon color="primary">info</Icon>
                </IconButton>
              </Box>
            </Tooltip>
          )}
        </Box>
        {IS_DEBUG && (
          <Box p={2}>
            <pre>
              {JSON.stringify(
                {
                  ...field,
                  value,
                },
                null,
                4
              )}
            </pre>
          </Box>
        )}
      </>
    )
  );
};

function needToRender(parser: Parser, field: CustomField) {
  if (field.expressionShow) {
    const value = parser.parse(field.expressionShow);
    return value.result ?? true;
  }

  return true;
}

export default FieldContainer;
