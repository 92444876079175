import { Box, Grid, Icon, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../models/form';

const useClasses = makeStyles({
  grid: { flex: 1, display: 'flex' },
  box: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  iconConainter: {
    textAlign: 'right',
    marginTop: 'auto',
  },
  icon: {
    color: 'white',
  },
});

type Props = {
  form: Form;
};

const FormBlock: React.FC<Props> = ({ form }) => {
  const history = useHistory();
  const classes = useClasses();

  const firstStep = form.steps[0];
  const firstStepId = firstStep ? firstStep.id : '';

  return (
    <Grid item md={4} xs={12} sm={6} className={classes.grid}>
      <Box
        p={3}
        style={{ background: `var(${form.color})` }}
        className={classes.box}
        onClick={() => history.push(`/form/${form.id}/${firstStepId}`)}
      >
        <Typography
          variant="h5"
          component="h2"
          style={{ color: 'white', marginBottom: '3rem' }}
        >
          {form.name}
        </Typography>
        <div className={classes.iconConainter}>
          <Icon className={classes.icon}>arrow_forward</Icon>
        </div>
      </Box>
    </Grid>
  );
};

export default FormBlock;
