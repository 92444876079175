import { MESSAGES } from '../constants/messages';
import { Parser } from 'hot-formula-parser';

export const validateNumber = (
  value: string | number | undefined,
  minValue?: number,
  maxValue?: number
) => {
  if (
    (minValue === undefined || (value !== undefined && value >= minValue)) &&
    (maxValue === undefined || (value !== undefined && value <= maxValue))
  ) {
    return { isValid: true, validationMessage: null };
  }

  return {
    isValid: false,
    validationMessage:
      minValue &&
      maxValue &&
      MESSAGES.numberError
        .replace('{0}', minValue.toString())
        .replace('{1}', maxValue.toString()),
  };
};

export function validate(
  parser: Parser,
  validationFormula?: string,
  isValidationActive: boolean = true
) {
  if (!validationFormula || !isValidationActive) {
    return { isValid: true, validationMessage: null };
  }

  const { result } = parser.parse(validationFormula);

  if (result === true) {
    return { isValid: true, validationMessage: null };
  }

  if (typeof result === 'string') {
    return { isValid: false, validationMessage: result };
  }

  return { isValid: false, validationMessage: MESSAGES.validationError };
}
