import {
  Box,
  CardContent,
  SwipeableDrawer,
  TextField,
  Typography,
  Card,
  CardActions,
  Icon,
  Grid,
} from '@material-ui/core';
import React, { Dispatch, useState, useContext } from 'react';
import { MESSAGES } from '../../constants/messages';
import { isEmpty, isMobile } from '../../helpers/helpers';
import Step from '../../models/step';
import { Action } from '../../reducers/formsReducer';
import { AddButton } from './AddButton';
import { DeleteButton } from './DeleteButton';
import { EditButton } from './EditButton';
import FieldBlock from './FieldBlock';
import { useCardClasses } from './Styled';
import { Container, Draggable, DropResult } from 'react-smooth-dnd';
import { Title } from './Title';
import { IsReadOnlyContext } from '../../pages/Admin';

type Props = {
  step: Step;
  formId: string;
  dispatch: Dispatch<Action>;
};

const StepBlock: React.FC<Props> = ({ step, formId, dispatch }) => {
  const classes = useCardClasses();
  const [isFieldsOpen, setIsFieldsOpen] = useState(false);
  const isReadOnly = useContext(IsReadOnlyContext);

  const openFields = () => setIsFieldsOpen(true);
  const closeFields = () => setIsFieldsOpen(false);

  const removeStep = (id: string) =>
    dispatch({
      type: 'REMOVE_STEP',
      payload: { formId, stepId: id },
    });

  const updateStep = (name: string, value: string) =>
    dispatch({
      type: 'UPDATE_STEP',
      payload: { formId, step: { ...step, [name]: value } },
    });

  const addField = () =>
    dispatch({ type: 'ADD_FIELD', payload: { formId, stepId: step.id } });

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => updateStep(event.target.name, event.target.value);

  const onDrop = ({ removedIndex, addedIndex }: DropResult) =>
    dispatch({
      type: 'REORDER_FIELD',
      payload: {
        formId,
        stepId: step.id,
        removedIndex: removedIndex as number,
        addedIndex: addedIndex as number,
      },
    });

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                <TextField
                  name="name"
                  fullWidth
                  variant="outlined"
                  label={MESSAGES.name}
                  value={step.name}
                  onChange={onChange}
                  disabled={isReadOnly}
                />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                <TextField
                  name="title"
                  fullWidth
                  variant="outlined"
                  label={MESSAGES.title}
                  value={step.title}
                  onChange={onChange}
                  disabled={isReadOnly}
                />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                <TextField
                  name="description"
                  fullWidth
                  variant="outlined"
                  label={MESSAGES.description}
                  value={step.description}
                  onChange={onChange}
                  disabled={isReadOnly}
                />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                <TextField
                  name="disclaimer"
                  fullWidth
                  variant="outlined"
                  label={MESSAGES.disclaimer}
                  value={step.disclaimer}
                  onChange={onChange}
                  disabled={isReadOnly}
                />
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <EditButton label={MESSAGES.fields} onClick={openFields} />
          {!isReadOnly && (
            <>
              <DeleteButton onClick={() => removeStep(step.id)} />
              <Box style={{ marginLeft: 'auto' }}>
                <Icon className="drag-handle">drag_handle</Icon>
              </Box>
            </>
          )}
        </CardActions>
      </Card>

      <SwipeableDrawer
        anchor="right"
        open={isFieldsOpen}
        onClose={closeFields}
        onOpen={openFields}
      >
        <Box p={2} style={{ width: !isMobile() ? '70vw' : 'auto' }}>
          <Title
            onClick={closeFields}
            title={`${step.name} - ${MESSAGES.fields}`}
          />

          {!isEmpty(step.fields) ? (
            <Container
              dragHandleSelector=".drag-handle"
              lockAxis="y"
              onDrop={onDrop}
            >
              {step.fields.map((field) => (
                <Draggable key={field.id}>
                  <FieldBlock
                    field={field}
                    formId={formId}
                    stepId={step.id}
                    dispatch={dispatch}
                  />
                </Draggable>
              ))}
            </Container>
          ) : (
            <Box pb={5}>{MESSAGES.noFields}</Box>
          )}

          {!isReadOnly && <AddButton onClick={addField} />}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default React.memo(StepBlock);
