export const palettes = {
  ssfb: {
    primary: {
      main: '#f78f1e',
    },
    secondary: {
      main: '#69321f',
    },
  },
  nbov: {
    primary: {
      main: '#ff6600',
    },
    secondary: {
      main: '#6b6b7d',
    },
  },
  fnv: {
    primary: {
      main: '#0099dc',
    },
    secondary: {
      main: '#62a316',
    },
  },
  nvb: {
    primary: {
      main: '#5e3419',
    },
    secondary: {
      main: '#a35018',
    },
  },
  cnv: {
    primary: {
      main: '#692f8c',
    },
    secondary: {
      main: '#004e9b',
    },
  },
};
