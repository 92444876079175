import {
  Step,
  StepButton,
  Stepper as MuiStepper,
  useTheme,
  useMediaQuery,
  MobileStepper,
  Button,
  Icon,
  StepLabel,
} from '@material-ui/core';
import React from 'react';
import { MESSAGES } from '../constants/messages';
import StepModel from '../models/step';
import { form } from '../constants/data';
import Report from '../models/report';

type Props = {
  steps: StepModel[];
  reportSlug: string;
  activeStepIndex: number;
  handleStepChange: (id: string) => void;
  prevStep: () => void;
  nextStep: () => void;
  report: Report;
  error: boolean;
};

export const Stepper: React.FC<Props> = (props) => {
  const {
    steps,
    activeStepIndex,
    reportSlug,
    handleStepChange,
    prevStep,
    nextStep,
    report,
    error,
  } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return matches ? (
    <MuiStepper nonLinear activeStep={activeStepIndex}>
      {steps.map((step, index) => (
        <Step key={step.id}>
          <StepButton
            disabled={activeStepIndex < index && error}
            completed={index < activeStepIndex}
            onClick={() => handleStepChange(step.id)}
          >
            <StepLabel error={error && activeStepIndex === index}>
              {step.name}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
      <Step>
        <StepButton
          onClick={() => handleStepChange(reportSlug)}
          disabled={error}
        >
          {report && report.name ? report.name : MESSAGES.report}
        </StepButton>
      </Step>
    </MuiStepper>
  ) : (
    <MobileStepper
      steps={steps.length + 1}
      position="static"
      variant="dots"
      activeStep={activeStepIndex}
      nextButton={
        <Button
          disabled={activeStepIndex === form.steps.length || error}
          onClick={nextStep}
          color="primary"
          variant="contained"
          endIcon={<Icon>arrow_forward</Icon>}
        >
          {MESSAGES.next}
        </Button>
      }
      backButton={
        <Button
          disabled={activeStepIndex === 0}
          onClick={prevStep}
          color="secondary"
          variant="contained"
          startIcon={<Icon>arrow_back</Icon>}
        >
          {MESSAGES.previous}
        </Button>
      }
    />
  );
};
