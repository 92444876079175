import { makeStyles } from '@material-ui/core';

export const useCardClasses = makeStyles({
  card: {
    marginBottom: '1rem',
  },
  cardActions: {
    paddingLeft: 16,
    paddingRight: 16,
  },
});
