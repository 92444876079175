import { makeStyles, TextField } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
  RenderOptionState,
} from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { useRef, useContext } from 'react';
import { FUNCTIONS } from '../../constants/functions';
import { MESSAGES } from '../../constants/messages';
import { normalize } from '../../helpers/helpers';
import { IsReadOnlyContext } from '../../pages/Admin';

type Props = {
  options: Option[];
  value: string;
  label?: string;
  onChange: (value: string) => void;
};

export type Option = {
  title: string;
  type: string;
};

const splitOnIndex = (value: string, index?: number | null) => {
  if (!index && index !== 0) {
    return [value, ''];
  }

  const start = value.slice(0, index);
  const end = value.slice(index);

  return [start, end];
};

const LAST_WORD_REGEX = /\w+$/;

const getLastWord = (value: string) => {
  const match = value.trim().match(LAST_WORD_REGEX);
  const lastWord = match?.[0];

  return lastWord;
};

const useClasses = makeStyles({
  noOptions: {
    display: 'none',
  },
});

const defaultFilterOptions = createFilterOptions<Option>();

const FormulaAutocomplete: React.FC<Props> = (props) => {
  const { options, value, label = MESSAGES.formula, onChange } = props;
  const classes = useClasses();
  const isReadOnly = useContext(IsReadOnlyContext);

  const combinedOptions = [...FUNCTIONS, ...options];

  const inputRef = useRef<HTMLInputElement>();

  const renderOption = (option: Option, { inputValue }: RenderOptionState) => {
    const [start] = splitOnIndex(inputValue, inputRef.current?.selectionStart);
    const lastWord = getLastWord(start) ?? '';
    const matches = match(option.title, lastWord);
    const parts = parse(option.title, matches);

    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  };

  const filterOptions = (
    options: Option[],
    state: FilterOptionsState<Option>
  ) => {
    const [start] = splitOnIndex(
      state.inputValue,
      inputRef.current?.selectionStart
    );
    const lastWord = getLastWord(start) ?? '';

    const newState = { ...state, inputValue: lastWord };

    return defaultFilterOptions(options, newState);
  };

  const onInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    if (!event || event.type === 'blur') {
      return;
    }

    let newValue = newInputValue;
    let length = 0;

    if (event && event.type !== 'change') {
      const [start, end] = splitOnIndex(
        value,
        inputRef.current?.selectionStart
      );
      // remove the start of the autocomplete if the user was typing it
      const lastWord = getLastWord(start);

      let replacedStart = start;

      if (lastWord && normalize(newValue).includes(normalize(lastWord))) {
        replacedStart = start.replace(LAST_WORD_REGEX, '');
      }

      length = (replacedStart + newValue).length;

      newValue = replacedStart + newValue + end;

      setTimeout(() => {
        const input = inputRef.current;
        if (input) {
          input.selectionEnd = length;
        }
      }, 100);
    }

    onChange(newValue);
  };

  return (
    <Autocomplete
      classes={classes}
      disableClearable
      options={combinedOptions}
      inputValue={value}
      getOptionLabel={(option) => option.title}
      groupBy={(option) => option.type}
      selectOnFocus={false}
      noOptionsText={MESSAGES.noOptions}
      renderOption={renderOption}
      filterOptions={filterOptions}
      onInputChange={onInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          label={label}
          variant="outlined"
          name="expression"
        />
      )}
      disabled={isReadOnly}
    />
  );
};

export default React.memo(FormulaAutocomplete);
