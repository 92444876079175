import { Box, Button, Grid, Icon } from '@material-ui/core';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { MESSAGES } from '../constants/messages';
import { isEmpty, scrollToElement } from '../helpers/helpers';
import Form from '../models/form';
import ReportBlock from './ReportBlock';
import StepBlock from './StepBlock';
import { Stepper } from './Stepper';
import { Parser } from 'hot-formula-parser';
import { validate } from '../helpers/validation';

type Props = {
  form: Form;
  parser: Parser;
};

export type FormState = {
  [key: string]: string | number | undefined | [string, number][];
};

const FormContainer: React.FC<Props> = ({ form, parser }) => {
  const history = useHistory();
  const params = useParams<{ id: string; stepId: string }>();

  const formRef = React.useRef();

  React.useEffect(() => scrollToElement(formRef.current), [params.stepId]);

  const initialState = {} as FormState;

  form.steps.forEach((step) =>
    step.fields.forEach((field) => {
      if (field.type === 'multiSelect') {
        initialState[field.name] = [];

        return;
      }

      if (field.type !== 'formula') {
        initialState[field.name] = field.defaultValue;
      }
    })
  );

  const [formState, setFormState] = useState(initialState);
  const [isValidationActive, setIsValidationActive] = useState(false);

  Object.entries(formState).map(([key, value]) =>
    parser.setVariable(key, value)
  );

  const handleFieldChange = (
    name: string,
    value: string | number | undefined
  ) => setFormState({ ...formState, [name]: value });

  const handleStepChange = (stepId: string) => {
    var activeStepIdx = getStepIndex(params.stepId);
    var nextStepIdx = getStepIndex(stepId);

    const isNextStepAhead = nextStepIdx > activeStepIdx;

    if (isError && isNextStepAhead) {
      setIsValidationActive(true);

      return;
    }

    setIsValidationActive(false);

    history.push(`/form/${form.id}/${stepId}`);
  };

  const prevStep = () => {
    handleStepChange(getStepId(activeStepIndex - 1));
  };

  const nextStep = () => {
    handleStepChange(getStepId(activeStepIndex + 1));
  };

  const REPORT_SLUG = 'report';

  const getStepId = (index: number) => {
    if (index === form.steps.length) {
      return REPORT_SLUG;
    }

    const step = form.steps[index];

    return step.id;
  };

  const getStepIndex = (id: string) => {
    if (id === REPORT_SLUG) {
      return form.steps.length;
    }

    const index = form.steps.findIndex((step) => step.id === id);

    return index;
  };

  const activeStepIndex = getStepIndex(params.stepId);

  let isError = false;

  const step = form.steps[activeStepIndex];

  if (step) {
    for (const field of step.fields) {
      // is validation active parameter is always true here
      const validation = validate(parser, field.validationFormula);
      if (!validation.isValid) {
        isError = true;
        break;
      }
    }
  }

  const isValidationFailed = isValidationActive && isError;

  return (
    <Grid container justify="center" innerRef={formRef}>
      <Grid
        item
        lg={10}
        xs={12}
        style={{
          background: 'white',
          marginBottom: '5rem',
          boxShadow: '0 25px 50px -12px rgba(0,0,0,.25)',
        }}
      >
        <Stepper
          activeStepIndex={activeStepIndex}
          steps={form.steps}
          reportSlug={REPORT_SLUG}
          handleStepChange={handleStepChange}
          nextStep={nextStep}
          prevStep={prevStep}
          report={form.report}
          error={isValidationFailed}
        />

        {!isEmpty(form.steps) ? (
          <Switch>
            {form.steps.map((step) => (
              <Route key={step.id} path={`/form/${form.id}/${step.id}`}>
                <StepBlock
                  step={step}
                  formId={form.id}
                  formState={formState}
                  parser={parser}
                  isValidationActive={isValidationActive}
                  handleFieldChange={handleFieldChange}
                />
              </Route>
            ))}
            <Route to={`/form/${form.id}/report`}>
              <ReportBlock form={form} parser={parser} />
            </Route>
          </Switch>
        ) : (
          <Box px={2} py={5}>
            {MESSAGES.noSteps}
          </Box>
        )}

        <Box p={2} display="flex" justifyContent="space-between">
          <Button
            disabled={activeStepIndex === 0}
            onClick={prevStep}
            color="secondary"
            variant="contained"
            startIcon={<Icon>arrow_back</Icon>}
          >
            {MESSAGES.previous}
          </Button>
          <Button
            disabled={
              activeStepIndex === form.steps.length || isValidationFailed
            }
            onClick={nextStep}
            color="primary"
            variant="contained"
            endIcon={<Icon>arrow_forward</Icon>}
          >
            {MESSAGES.next}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormContainer;
