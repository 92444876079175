import { Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Dispatch, useContext } from 'react';
import { MESSAGES } from '../../constants/messages';
import { SelectField as Field } from '../../models/field';
import { Action } from '../../reducers/formsReducer';
import { IsReadOnlyContext } from '../../pages/Admin';

type Props = {
  field: Field;
  formId: string;
  stepId: string;
  dispatch: Dispatch<Action>;
};

export const SelectField: React.FC<Props> = ({
  field,
  formId,
  stepId,
  dispatch,
}) => {
  const isReadOnly = useContext(IsReadOnlyContext);

  const handleChange = (name: string, value: string[]) => {
    const newField = { ...field, [name]: value };

    dispatch({
      type: 'UPDATE_FIELD',
      payload: { formId, stepId, field: newField },
    });
  };

  return (
    <Autocomplete
      multiple
      options={[] as string[]}
      freeSolo
      value={field.values || []}
      disableClearable
      onChange={(event, value) => handleChange('values', value)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option}
            {...getTagProps({
              index,
            })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={MESSAGES.values}
          disabled={isReadOnly}
        />
      )}
      disabled={isReadOnly}
    />
  );
};
