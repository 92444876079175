import { Box } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router-dom';
import { MESSAGES } from '../constants/messages';
import { isEmpty } from '../helpers/helpers';
import StepModel from '../models/step';
import { FormState } from './FormContainer';
import FieldContainer from './FieldContainer';
import { Parser } from 'hot-formula-parser';
import { IS_DEBUG } from '../constants/config';

type Props = {
  step: StepModel;
  formId: string;
  formState: FormState;
  parser: Parser;
  isValidationActive: boolean;
  handleFieldChange: (name: string, value: string | number | undefined) => void;
};

const StepBlock: React.FC<Props> = ({
  step,
  formId,
  parser,
  formState,
  isValidationActive,
  handleFieldChange,
}) => {
  return (
    <Route path={`/form/${formId}/${step.id}`}>
      {(step.title || step.description) && (
        <Box p={2}>
          {step.title && <h2>{step.title}</h2>}
          {step.description && <p>{step.description}</p>}
        </Box>
      )}
      {!isEmpty(step.fields) ? (
        step.fields.map((field) => (
          <FieldContainer
            key={field.id}
            field={field}
            parser={parser}
            formState={formState}
            isValidationActive={isValidationActive}
            handleFieldChange={handleFieldChange}
          />
        ))
      ) : (
        <Box p={2} pb={5}>
          {MESSAGES.noFields}
        </Box>
      )}
      {step.disclaimer && (
        <Box p={2}>
          <p>{step.disclaimer}</p>
        </Box>
      )}
      {IS_DEBUG && (
        <Box p={2}>
          <pre>{JSON.stringify(parser.variables, null, 4)}</pre>
        </Box>
      )}
    </Route>
  );
};

export default StepBlock;
