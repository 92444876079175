import { Fab, Icon, makeStyles, Zoom } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useContext } from 'react';
import { v4 } from 'uuid';
import { MESSAGES } from '../../../constants/messages';
import { isEmpty } from '../../../helpers/helpers';
import { useForms } from '../../../hooks/useForms';
import { AddButton } from '../AddButton';
import FormBlock from '../FormBlock';
import { IsReadOnlyContext, TokenContext } from '../../../pages/Admin';
import { IS_DEBUG } from '../../../constants/config';

const useStyles = makeStyles({
  fab: {
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
    zIndex: 10000,
  },
});

const AdminForms = () => {
  const [forms, dispatch] = useForms();
  const classes = useStyles();

  const isReadOnly = useContext(IsReadOnlyContext);
  const token = useContext(TokenContext);

  const addForm = () =>
    dispatch({
      type: 'ADD_FORM',
      payload: {
        form: {
          id: v4(),
          name: MESSAGES.newForm,
          steps: [],
          reportGroups: [],
          color: '--primary',
          isDirty: false,
          report: { name: '', title: '', disclaimer: '' },
        },
        token,
      },
    });

  return (
    <>
      <Box p={2}>
        <h2>{MESSAGES.forms}</h2>
      </Box>
      <Box p={2}>
        {!isEmpty(forms) ? (
          forms.map((form) => (
            <FormBlock key={form.id} form={form} dispatch={dispatch} />
          ))
        ) : (
          <Box pb={5}>{MESSAGES.noForms}</Box>
        )}

        {!isReadOnly && <AddButton onClick={addForm} />}
      </Box>

      {!isReadOnly && (
        <Zoom in={!!forms.find((form) => form.isDirty)}>
          <Fab
            variant="extended"
            color="primary"
            aria-label={MESSAGES.save}
            className={classes.fab}
            onClick={() => {
              dispatch({ type: 'SAVE_FORMS', payload: { forms, token } });

              if (IS_DEBUG) {
                navigator.clipboard.writeText(JSON.stringify(forms));
              }
            }}
          >
            <Icon>save</Icon>
            <Box ml={1}>{MESSAGES.save}</Box>
          </Fab>
        </Zoom>
      )}
    </>
  );
};

export default AdminForms;
