import React from 'react';
import { Button, Icon } from '@material-ui/core';

import { MESSAGES } from '../../constants/messages';

type Props = {
  onClick: () => void;
};

export const AddButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button
      color="primary"
      variant="contained"
      fullWidth
      startIcon={<Icon>add_circle</Icon>}
      onClick={onClick}
    >
      {MESSAGES.add}
    </Button>
  );
};
