import DateFnsUtils from '@date-io/date-fns';
import { InputLabelProps, TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { nl } from 'date-fns/esm/locale';
import moment from 'moment';
import React, { useContext } from 'react';
import { MESSAGES } from '../constants/messages';
import { IsReadOnlyContext } from '../pages/Admin';

type Props = {
  name: string;
  label: string;
  value: string | number | undefined;
  variant?: 'outlined';
  placeholder?: string;
  isValid?: boolean;
  validationMessage?: string | null;
  onChange: (name: string, value: string | number) => void;
};

const DatePickerInput: React.FC<Props> = (props) => {
  const {
    name,
    label,
    value,
    variant = 'standard',
    placeholder,
    isValid = true,
    validationMessage,
    onChange,
  } = props;

  const isReadOnly = useContext(IsReadOnlyContext);

  const handleDateChange = (date: MaterialUiPickersDate | null) => {
    onChange(name, moment(date).format('yyyy-MM-DD'));
  };

  const inputLabelProps = {} as Partial<InputLabelProps>;

  if (placeholder) {
    inputLabelProps.shrink = true;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
      <DatePicker
        TextFieldComponent={TextField}
        autoOk
        maxDateMessage={MESSAGES.invalid}
        minDateMessage={MESSAGES.invalid}
        invalidDateMessage={MESSAGES.invalid}
        fullWidth
        variant="inline"
        inputVariant={variant}
        format="dd-MM-yyyy"
        margin="normal"
        id={`${name}Id`}
        label={label}
        value={value ? moment(value) : null}
        onChange={handleDateChange}
        inputProps={{ name }}
        InputLabelProps={inputLabelProps}
        placeholder={placeholder}
        disabled={isReadOnly}
        error={!isValid}
        helperText={validationMessage}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerInput;
