export const BASE_API_URL_PRODUCTION = 'https://api.loonberekeningsmodule.nl';
export const BASE_API_URL_TEST = 'https://apitest.loonberekeningsmodule.nl';
export const BASE_API_URL_DEVELOPMENT = 'https://ssbf-api.esoftware.lt';

export const BASE_API_URL = BASE_API_URL_DEVELOPMENT;

export const API_FORMS = BASE_API_URL + '/form';
export const API_TABLES = BASE_API_URL + '/datatable';

export const ENDPOINTS = {
  formsGetAll: API_FORMS + '/read.php',
  formsCreate: API_FORMS + '/create.php',
  formsDelete: API_FORMS + '/delete.php',
  formsGet: API_FORMS + '/read_one.php',
  formsUpdate: API_FORMS + '/update.php',
  tablesGetAll: API_TABLES + '/read.php',
  tablesCreate: API_TABLES + '/create.php',
  tablesDelete: API_TABLES + '/delete.php',
  tablesGet: API_TABLES + '/read_one.php',
  tablesUpdate: API_TABLES + '/update.php',
  mailSend: BASE_API_URL + '/mail/send.php',
};
