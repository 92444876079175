import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormContainer from '../components/FormContainer';
import { useForms } from '../hooks/useForms';
import { useTables } from '../hooks/useTables';
import parser from '../helpers/parser';

const User = () => {
  const params = useParams<{ id: string; stepId: string }>();
  const [forms, dispatch] = useForms();
  const [{ tables }] = useTables();

  useEffect(() => {
    tables.forEach((table) => parser.setVariable(table.name, table.data));
    // HACK: this doesn't actually change anything
    // fire off an update to update values for the new parser
    // we need this since the parser is not in sate for now
    dispatch({ type: 'RECEIVE_FORMS', payload: { forms } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tables]);

  const form = forms.find((item) => item.id === params.id);

  if (!form) {
    return null;
  }

  return <FormContainer form={form} parser={parser} />;
};

export default User;
