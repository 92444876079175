import arrayMove from 'array-move';
import { v4 } from 'uuid';
import { MESSAGES } from '../constants/messages';
import { CustomField } from '../models/field';
import Form from '../models/form';
import { Formula } from '../models/formula';
import Step from '../models/step';
import Report from '../models/report';

export type Action =
  | { type: 'REQUEST_FORMS' }
  | { type: 'RECEIVE_FORMS'; payload: { forms: Form[] } }
  | { type: 'SAVE_FORMS'; payload: { forms: Form[]; token: string } }
  | { type: 'REMOVE_FORM'; payload: { formId: string; token: string } }
  | { type: 'ADD_FORM'; payload: { form: Form; token: string } }
  | { type: 'UPDATE_FORM'; payload: { formId: string; name: string } }
  | { type: 'REMOVE_STEP'; payload: { formId: string; stepId: string } }
  | { type: 'ADD_STEP'; payload: { formId: string } }
  | {
      type: 'UPDATE_STEP';
      payload: { formId: string; step: Partial<Step> };
    }
  | {
      type: 'REORDER_STEP';
      payload: {
        formId: string;
        removedIndex: number;
        addedIndex: number;
      };
    }
  | {
      type: 'REMOVE_FIELD';
      payload: { formId: string; stepId: string; fieldId: string };
    }
  | { type: 'ADD_FIELD'; payload: { formId: string; stepId: string } }
  | {
      type: 'UPDATE_FIELD';
      payload: { formId: string; stepId: string; field: CustomField };
    }
  | {
      type: 'REORDER_FIELD';
      payload: {
        formId: string;
        stepId: string;
        removedIndex: number;
        addedIndex: number;
      };
    }
  | { type: 'ADD_GROUP'; payload: { formId: string } }
  | {
      type: 'UPDATE_GROUP';
      payload: { formId: string; groupId: string; name: string };
    }
  | { type: 'REMOVE_GROUP'; payload: { formId: string; groupId: string } }
  | {
      type: 'REORDER_GROUP';
      payload: { formId: string; removedIndex: number; addedIndex: number };
    }
  | { type: 'ADD_FORMULA'; payload: { formId: string; groupId: string } }
  | {
      type: 'UPDATE_FORMULA';
      payload: { formId: string; groupId: string; formula: Formula };
    }
  | {
      type: 'REMOVE_FORMULA';
      payload: { formId: string; groupId: string; formulaId: string };
    }
  | {
      type: 'REORDER_FORMULA';
      payload: {
        formId: string;
        groupId: string;
        removedIndex: number;
        addedIndex: number;
      };
    }
  | { type: 'UPDATE_REPORT'; payload: { formId: string; report: Report } };

export type State = Form[];

export const formsReducer = (state: State, action: Action): State => {
  if (action.type === 'RECEIVE_FORMS') {
    return [...action.payload.forms];
  }

  if (action.type === 'SAVE_FORMS') {
    return state.map((form) => ({ ...form, isDirty: false }));
  }

  if (action.type === 'ADD_FORM') {
    return [
      ...state,
      {
        id: v4(),
        name: MESSAGES.newForm,
        steps: [],
        reportGroups: [],
        color: '--primary',
        isDirty: false,
        ...action.payload.form,
      },
    ];
  }

  if (action.type === 'REMOVE_FORM') {
    return state.filter((form) => form.id !== action.payload.formId);
  }

  if (action.type === 'UPDATE_FORM') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? { ...form, name: action.payload.name, isDirty: true }
        : form
    );
  }

  if (action.type === 'ADD_STEP') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            steps: [
              ...form.steps,
              { id: v4(), name: MESSAGES.newStep, fields: [] },
            ],
          }
        : form
    );
  }

  if (action.type === 'REMOVE_STEP') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            steps: form.steps.filter(
              (step) => step.id !== action.payload.stepId
            ),
          }
        : form
    );
  }

  if (action.type === 'UPDATE_STEP') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            steps: form.steps.map((step) =>
              step.id === action.payload.step.id
                ? { ...step, ...action.payload.step }
                : step
            ),
          }
        : form
    );
  }

  if (action.type === 'REORDER_STEP') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            steps: arrayMove(
              form.steps,
              action.payload.removedIndex,
              action.payload.addedIndex
            ),
          }
        : form
    );
  }

  if (action.type === 'ADD_FIELD') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            steps: form.steps.map((step) =>
              step.id === action.payload.stepId
                ? {
                    ...step,
                    fields: [
                      ...step.fields,
                      {
                        id: v4(),
                        name: MESSAGES.field,
                        label: MESSAGES.newField,
                        type: 'text',
                      },
                    ],
                  }
                : step
            ),
          }
        : form
    );
  }

  if (action.type === 'REMOVE_FIELD') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            steps: form.steps.map((step) =>
              step.id === action.payload.stepId
                ? {
                    ...step,
                    fields: step.fields.filter(
                      (field) => action.payload.fieldId !== field.id
                    ),
                  }
                : step
            ),
          }
        : form
    );
  }

  if (action.type === 'UPDATE_FIELD') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            steps: form.steps.map((step) =>
              step.id === action.payload.stepId
                ? {
                    ...step,
                    fields: step.fields.map((field) =>
                      field.id === action.payload.field.id
                        ? { ...action.payload.field }
                        : field
                    ),
                  }
                : step
            ),
          }
        : form
    );
  }

  if (action.type === 'REORDER_FIELD') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            steps: form.steps.map((step) =>
              step.id === action.payload.stepId
                ? {
                    ...step,
                    fields: arrayMove(
                      step.fields,
                      action.payload.removedIndex,
                      action.payload.addedIndex
                    ),
                  }
                : step
            ),
          }
        : form
    );
  }

  if (action.type === 'ADD_GROUP') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            reportGroups: [
              ...form.reportGroups,
              {
                id: v4(),
                name: MESSAGES.reportGroup,
                label: MESSAGES.newReportGroup,
                formulas: [],
              },
            ],
          }
        : form
    );
  }

  if (action.type === 'REMOVE_GROUP') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            reportGroups: form.reportGroups.filter(
              (group) => group.id !== action.payload.groupId
            ),
          }
        : form
    );
  }

  if (action.type === 'UPDATE_GROUP') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            reportGroups: form.reportGroups.map((group) =>
              group.id === action.payload.groupId
                ? { ...group, name: action.payload.name }
                : group
            ),
          }
        : form
    );
  }

  if (action.type === 'REORDER_GROUP') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            reportGroups: arrayMove(
              form.reportGroups,
              action.payload.removedIndex,
              action.payload.addedIndex
            ),
          }
        : form
    );
  }

  if (action.type === 'ADD_FORMULA') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            reportGroups: form.reportGroups.map((group) =>
              group.id === action.payload.groupId
                ? {
                    ...group,
                    formulas: [
                      ...group.formulas,
                      {
                        id: v4(),
                        label: MESSAGES.newFormula,
                        expression: '',
                        name: MESSAGES.formula,
                      },
                    ],
                  }
                : group
            ),
          }
        : form
    );
  }

  if (action.type === 'REMOVE_FORMULA') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            reportGroups: form.reportGroups.map((group) =>
              group.id === action.payload.groupId
                ? {
                    ...group,
                    formulas: group.formulas.filter(
                      (formula) => formula.id !== action.payload.formulaId
                    ),
                  }
                : group
            ),
          }
        : form
    );
  }

  if (action.type === 'UPDATE_FORMULA') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            reportGroups: form.reportGroups.map((group) =>
              group.id === action.payload.groupId
                ? {
                    ...group,
                    formulas: group.formulas.map((formula) =>
                      formula.id === action.payload.formula.id
                        ? { ...formula, ...action.payload.formula }
                        : formula
                    ),
                  }
                : group
            ),
          }
        : form
    );
  }

  if (action.type === 'REORDER_FORMULA') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            reportGroups: form.reportGroups.map((group) =>
              group.id === action.payload.groupId
                ? {
                    ...group,
                    formulas: arrayMove(
                      group.formulas,
                      action.payload.removedIndex,
                      action.payload.addedIndex
                    ),
                  }
                : group
            ),
          }
        : form
    );
  }

  if (action.type === 'UPDATE_REPORT') {
    return state.map((form) =>
      form.id === action.payload.formId
        ? {
            ...form,
            isDirty: true,
            report: { ...form.report, ...action.payload.report },
          }
        : form
    );
  }

  return state;
};
