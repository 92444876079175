import { Box, Grid } from '@material-ui/core';
import React from 'react';
import FormBlock from '../components/FormBlock';
import { MESSAGES } from '../constants/messages';
import { isEmpty } from '../helpers/helpers';
import { useForms } from '../hooks/useForms';

const Home = () => {
  const [forms] = useForms();

  return (
    <Box p={3}>
      <Grid container justify="center" spacing={3}>
        {!isEmpty(forms) ? (
          forms.map((form) => <FormBlock key={form.id} form={form} />)
        ) : (
          <Box pb={5}>{MESSAGES.noForms}</Box>
        )}
      </Grid>
    </Box>
  );
};

export default Home;
