import { Box, Grid, Typography, TextField } from '@material-ui/core';
import React, { Dispatch, useContext } from 'react';
import { Container, Draggable, DropResult } from 'react-smooth-dnd';
import { MESSAGES } from '../../constants/messages';
import { isEmpty } from '../../helpers/helpers';
import Form from '../../models/form';
import { Action } from '../../reducers/formsReducer';
import { AddButton } from './AddButton';
import ReportGroupBlock from './ReportGroupBlock';
import { IsReadOnlyContext } from '../../pages/Admin';

type Props = {
  form: Form;
  dispatch: Dispatch<Action>;
};

const ReportBlock: React.FC<Props> = ({ form, dispatch }) => {
  const isReadOnly = useContext(IsReadOnlyContext);

  const addGroup = () =>
    dispatch({ type: 'ADD_GROUP', payload: { formId: form.id } });

  const onDrop = (
    { removedIndex, addedIndex }: DropResult,
    type: 'REORDER_GROUP'
  ) =>
    dispatch({
      type,
      payload: {
        formId: form.id,
        removedIndex: removedIndex as number,
        addedIndex: addedIndex as number,
      },
    });

  const onGroupDrop = (result: DropResult) => onDrop(result, 'REORDER_GROUP');

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => updateReport(event.target.name, event.target.value);

  const updateReport = (name: string, value: string) =>
    dispatch({
      type: 'UPDATE_REPORT',
      payload: { formId: form.id, report: { ...form.report, [name]: value } },
    });

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h5" component="h2">
            <TextField
              name="name"
              fullWidth
              variant="outlined"
              label={MESSAGES.name}
              value={form.report ? form.report.name : ''}
              onChange={onChange}
              disabled={isReadOnly}
            />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" component="h2">
            <TextField
              name="title"
              fullWidth
              variant="outlined"
              label={MESSAGES.title}
              value={form.report ? form.report.title : ''}
              onChange={onChange}
              disabled={isReadOnly}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            <TextField
              multiline={true}
              rowsMax={5}
              name="disclaimer"
              fullWidth
              variant="outlined"
              label={MESSAGES.disclaimer}
              value={form.report ? form.report.disclaimer : ''}
              onChange={onChange}
              disabled={isReadOnly}
            />
          </Typography>
        </Grid>
      </Grid>
      <Box pt={3}>
        <Typography variant="h5" component="h2">
          {MESSAGES.reportGroups}
        </Typography>
      </Box>
      {!isEmpty(form.reportGroups) ? (
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={onGroupDrop}
        >
          {form.reportGroups.map((group) => (
            <Draggable key={group.id}>
              <ReportGroupBlock
                formId={form.id}
                group={group}
                dispatch={dispatch}
              />
            </Draggable>
          ))}
        </Container>
      ) : (
        <Box pb={5}>{MESSAGES.noReportGroups}</Box>
      )}
      {!isReadOnly && <AddButton onClick={addGroup} />}
    </Box>
  );
};

export default ReportBlock;
