import React from 'react';
import { Box, Icon } from '@material-ui/core';

type Props = {
  onClick: () => void;
  title: string;
};

export const Title: React.FC<Props> = ({ title, onClick }) => {
  return (
    <h2>
      <Box display="flex" alignItems="center">
        <Icon onClick={onClick}>arrow_back</Icon> {title}
      </Box>
    </h2>
  );
};
