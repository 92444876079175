import React, { useState } from 'react';
import {
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { MESSAGES } from '../../constants/messages';

type Props = {
  onClick: () => void;
};

export const DeleteButton: React.FC<Props> = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <>
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={isOpen}
        onClose={close}
      >
        <DialogTitle id="confirmation-dialog-title">
          {MESSAGES.delete}
        </DialogTitle>
        <DialogContent>{MESSAGES.deletionConfirmation}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            {MESSAGES.cancel}
          </Button>
          <Button onClick={onClick} color="primary" variant="contained">
            {MESSAGES.delete}
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={open} startIcon={<Icon>delete</Icon>}>
        {MESSAGES.delete}
      </Button>
    </>
  );
};
