import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import html2pdf from 'html2pdf.js';
import React, { useState } from 'react';
import { sendEmail } from '../../api/exportAPI';
import { ALERT_MESSAGES, MESSAGES } from '../../constants/messages';
import { options } from '../../constants/report';
import { logError } from '../../helpers/helpers';
import { useAlerts } from '../../hooks/useAlerts';

const SendEmailButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const [Alerts, setAlert] = useAlerts({
    success: ALERT_MESSAGES.emailSucces,
    info: ALERT_MESSAGES.emailInfo,
    error: ALERT_MESSAGES.emailError,
  });

  const handleClick = async () => {
    try {
      setAlert('info', true);
      close();
      await send(email);
      setAlert('success', true);
    } catch {
      setAlert('error', true);
    }
  };

  return (
    <>
      <Dialog maxWidth="sm" fullWidth={true} open={isOpen} onClose={close}>
        <DialogTitle id="confirmation-dialog-title">
          {MESSAGES.sendEmail}
        </DialogTitle>
        <DialogContent>
          <TextField
            label={MESSAGES.emailToSent}
            fullWidth
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            variant="outlined"
            name="label"
            type="email"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            {MESSAGES.cancel}
          </Button>
          <Button
            onClick={handleClick}
            color="primary"
            variant="contained"
            disabled={!/\S+@\S+\.\S+/.test(email)}
          >
            {MESSAGES.sendEmail}
          </Button>
        </DialogActions>
      </Dialog>

      <Button variant="contained" color="secondary" fullWidth onClick={open}>
        {MESSAGES.sendEmail}
      </Button>

      <Alerts />
    </>
  );
};

async function send(email: string) {
  try {
    const table = window.document.getElementById('report-table') as HTMLElement;
    const blob = await html2pdf().set(options).from(table).output('blob');

    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = function () {
      const urlData = (reader.result ?? '') as string;
      const base64Data = urlData.split(',')[1];

      sendEmail(email, base64Data as string);
    };
  } catch (error) {
    throw logError(error);
  }
}

export default SendEmailButton;
