import { Dispatch } from 'react';
import { Action } from '../reducers/formsReducer';
import {
  updateForm,
  deleteForm,
  createForm,
  getAllForms,
} from '../api/formsAPI';

export const formsDispatchMiddleware = (dispatch: Dispatch<Action>) => {
  return async (action: Action) => {
    if (action.type === 'REQUEST_FORMS') {
      dispatch(action);

      const forms = await getAllForms();

      return dispatch({ type: 'RECEIVE_FORMS', payload: { forms } });
    }

    if (action.type === 'ADD_FORM') {
      await createForm(action.payload.form, action.payload.token);
    }

    if (action.type === 'REMOVE_FORM') {
      await deleteForm(action.payload.formId, action.payload.token);
    }

    if (action.type === 'SAVE_FORMS') {
      const promises = action.payload.forms
        .filter((form) => form.isDirty)
        .map((form) => updateForm({ ...form, isDirty: false }, action.payload.token));

      await Promise.all(promises);
    }

    return dispatch(action);
  };
};
